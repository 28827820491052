import axios from 'axios';

export class AccountsFeatureFlagsClient {
  async getBooleanFlag(
    flagKey: string,
    defaultValue: boolean,
  ): Promise<boolean> {
    try {
      const response = await axios.get(
        `/api/features/boolean/${flagKey}/${defaultValue}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject({
        reason: (error as Error).message,
        value: false,
      });
    }
  }

  async getStringFlag(flagKey: string, defaultValue: string): Promise<string> {
    try {
      const response = await axios.get(
        `/api/features/string/${flagKey}/${defaultValue}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject({
        reason: (error as Error).message,
        value: false,
      });
    }
  }

  getNumberFlag() {
    return Promise.reject('Unsupported');
  }

  async getObjectFlag(flagKey: string): Promise<any> {
    try {
      const response = await axios.get(`/api/features/object/${flagKey}`);
      return response.data;
    } catch (error) {
      return Promise.reject({
        reason: (error as Error).message,
        value: false,
      });
    }
  }
}

const client = new AccountsFeatureFlagsClient();

export default client;
