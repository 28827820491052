import {useQuery, UseQueryResult} from '@tanstack/react-query';
import featureFlagClient from '../featureflags/FeatureFlagProvider';

const handleError = (error: any) => {
  console.error(error);
  return false;
};

export const useBooleanFeatureFlag = (
  key: string,
  defaultValue = false,
): UseQueryResult<boolean> => {
  return useQuery(
    ['booleanFeatureFlag', key],
    () => {
      return featureFlagClient
        .getBooleanFlag(key, defaultValue)
        .catch(handleError);
    },
    {refetchOnWindowFocus: false},
  );
};

export const useStringFlag = (
  key: string,
  defaultValue: string,
  enabled = true,
): UseQueryResult<string> => {
  return useQuery(
    ['stringFeatureFlag', key],
    () => {
      return featureFlagClient
        .getStringFlag(key, defaultValue)
        .catch(handleError);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
};

export const useObjectFeatureFlag = (
  key: string,
  enabled = true,
): UseQueryResult<any> => {
  return useQuery(
    ['objectFeatureFlag', key],
    () => {
      return featureFlagClient.getObjectFlag(key).catch(handleError);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
};
