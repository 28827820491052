import {intlShape, toast} from '@ecosio/components';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'semantic-ui-react';
import {CONTEXT_PATH} from '../../../../../constants';
import {useFetchedMfaSettings} from '../MfaSettings';
import {modalActions} from '../mfaStore';
import MfaTokenVerifier from './MfaTokenVerifier';
import PasswordVerifier from './PasswordVerifier';
import TOTPVerifier from './TOTPVerifier';

const TOTP_MFA_STATUS = {
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  TOKEN_VERIFY_REQUIRED: 'TOKEN_VERIFY_REQUIRED',
  TOTP_VERIFY_REQUIRED: 'TOTP_VERIFY_REQUIRED',
};

/**
 * Component that handles the MFA setup via a modal.<br>
 * For each step of the setup, there is a "modal page" handling the specific feature
 * See also {@link PasswordVerifier}, {@link TOTPVerifier}, {@link MfaTokenVerifier}
 * @param {*} intl
 * @returns
 */
const MfaSetupModal = ({intl}) => {
  const [setupStatus, setSetupStatus] = useState(
    TOTP_MFA_STATUS.PASSWORD_REQUIRED,
  );
  const modalState = useSelector((state) => state.modal);
  const mfaSettings = useSelector((state) => state.mfaSettings);
  const dispatch = useDispatch();
  const fetchMfaSettings = useFetchedMfaSettings();

  useEffect(() => {
    setSetupStatus(TOTP_MFA_STATUS.PASSWORD_REQUIRED);
  }, [modalState]);

  const toggleTotpMfa = () => {
    const newMfaSettings = {
      ...mfaSettings,
      totpEnabled: !mfaSettings.totpEnabled,
    };
    delete newMfaSettings.mfaEnforcedOnCompany;
    try {
      return axios({
        method: 'PUT',
        url: '/api/mfa/totp/toggle',
        data: newMfaSettings,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: intl.formatMessage({id: 'GENERAL_ERROR'}),
        description: error.message,
        type: 'error',
      });
    }
  };

  const onSetupFinish = async () => {
    await toggleTotpMfa();
    await fetchMfaSettings();
    dispatch(modalActions.close());
    // If the user disabled/enabled MFA, and MFA is enforced on the company, we redirect
    // This way we get an updated state of the config
    // If the user disabled MFA, then he/she will be redirected here on the MFA page
    if (mfaSettings.mfaEnforcedOnCompany) {
      window.location.href = CONTEXT_PATH;
    }
  };

  return (
    <Modal
      style={{width: '400px'}}
      open={modalState.isOpen}
      data-spec="mfa-setup-modal">
      {setupStatus === TOTP_MFA_STATUS.PASSWORD_REQUIRED && (
        <PasswordVerifier
          intl={intl}
          onSuccess={() => {
            setSetupStatus(TOTP_MFA_STATUS.TOKEN_VERIFY_REQUIRED);
          }}
          onRedirect={() =>
            setSetupStatus(TOTP_MFA_STATUS.TOTP_VERIFY_REQUIRED)
          }></PasswordVerifier>
      )}
      {setupStatus === TOTP_MFA_STATUS.TOKEN_VERIFY_REQUIRED && (
        <MfaTokenVerifier
          intl={intl}
          onSuccess={onSetupFinish}></MfaTokenVerifier>
      )}
      {setupStatus === TOTP_MFA_STATUS.TOTP_VERIFY_REQUIRED && (
        <TOTPVerifier intl={intl} onSuccess={onSetupFinish}></TOTPVerifier>
      )}
    </Modal>
  );
};

MfaSetupModal.propTypes = {
  intl: intlShape.isRequired,
  onInviteSent: PropTypes.func,
};

export default MfaSetupModal;
