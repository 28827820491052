import qs from 'qs';
import axios from 'axios';
import {toast} from '@ecosio/components';

export const isProd = () => import.meta.env.MODE === 'production';

export const dynID = (translationID) => {
  if (!translationID || typeof translationID !== 'string') {
    console.error(
      `[@ecosio/components] Dynamic translationkey ${translationID} not found - using fallbackID`,
    );
    return 'GENERAL_TRANSLATION_NOT_FOUND';
  }
  return translationID;
};

// TODO: this should be moved in a common form-processing-and-validation library
export const processValidationResult = (validationDataObject, intl) => {
  if (!validationDataObject) {
    throw new Error('No validation object passed');
  }

  if (!intl) {
    throw new Error('No intl passed');
  }

  return Object.keys(validationDataObject)
    .filter((k) => k !== 'status')
    .reduce((obj, key) => {
      obj[key] = intl.formatMessage({id: dynID(validationDataObject[key])});
      return obj;
    }, {});
};

/**
 * Stringify an object for use as query string in an URl.
 * Will stringify arrays without indices, e.g. foo=1&foo=2&foo=3
 * @param params
 * @param options
 * @return {string|*}
 */
export const stringifyQuery = (
  params,
  options = {addQueryPrefix: false, indices: false},
) => {
  if (params === null || typeof params !== 'object') {
    console.warn('Invalid params passed, will stringify to empty string');
    return '';
  }

  return qs.stringify(params, {...options, indices: false});
};

export const wrapFormSubmit = (intl, url, body, onSuccess) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, body)
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
        resolve(undefined);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          // the confirmation field is called passwordConfirmation on the client,
          // does not match the DTO
          if (error.response?.data?.newPassword) {
            error.response.data.passwordConfirmation =
              error.response.data.newPassword;
            delete error.response.data.newPassword;
          }

          // e.g. user already exists, this will not be mapped on the form otherwise
          if (error.response?.data?.error) {
            console.error(error);

            if (typeof error.response?.data?.error === 'string') {
              toast({
                title: intl.formatMessage({
                  id: 'GENERAL_ERROR',
                }),
                description: error.response?.data?.error,
                type: 'error',
              });
            }
          }

          resolve(processValidationResult(error.response?.data, intl));
        } else {
          reject(error);
        }
      });
  });
};
