import React from 'react';
import CheckboxList from './CheckboxList';

const CheckboxListField = ({input, meta, options, ...rest}) => {
  const onChange = (value) => {
    if (value === '') {
      input.onChange([]);
    } else {
      input.onChange(value);
    }
  };

  return (
    <CheckboxList
      {...input}
      {...rest}
      onChange={onChange}
      value={input.value}
      options={options}
      error={meta.error ? meta.error : meta.submitError}
    />
  );
};

export default CheckboxListField;
