import {useMemo} from 'react';
import {useLocation} from 'react-router-dom/cjs/react-router-dom';
import qs from 'qs';

/**
 * @param defaultValues {Record<string, string | string[]>?}
 * @returns {Record<string, string | string[]>}
 */
export const useQueryParams = (defaultValues) => {
  const location = useLocation();
  return useMemo(() => {
    const parsed = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (typeof defaultValues === 'object') {
      return {...defaultValues, ...parsed};
    } else {
      return parsed;
    }
  }, [location.search, defaultValues]);
};
