import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Analytics} from '@ecosio/components';
import {ecosioColors, NewHeader, NewSidebar} from '@ecosio/customer-layout';
import {shapes} from '@ecosio/auth';
import {Route, withRouter, Switch} from 'react-router-dom';
import {localesMap as fallbackLocales, switchLocale} from '@ecosio/locales';
import {Helmet} from 'react-helmet';
import {styled, ThemeProvider} from 'styled-components';
import SessionExpiredDialog from '../Session/SessionExpiredDialog';
import logger from '../../logger';
import Unauthenticated from './UnauthenticatedLayout';

const debug = logger('layout');

const Viewport = styled.div`
  padding-top: ${(props) => (props.noPadding ? '0' : '64px')};
`;

const mapStateToProps = ({locales, config}) => ({
  locale: locales.locale,
  config,
});

export const loadTheme = (configTheme, themeColors) => {
  const theme = configTheme?.split('/')[1];
  const selectedTheme = Object.keys(ecosioColors.themes).find(
    (themeName) => themeName === theme,
  );
  return themeColors[selectedTheme]
    ? themeColors[selectedTheme]
    : themeColors.ecosio;
};

const SidebarRoute = ({path, config, children}) => (
  <Route
    path={path}
    render={() => (
      <NewSidebar
        accountsBaseUrl={config.authServer}
        isAccounts
        authorities={config.userConfig?.authorities}
        userConfig={config.userConfig}>
        {children}
      </NewSidebar>
    )}
  />
);

const LayoutComponent = ({config, children, locale, baseUrl, location}) => {
  if (!config.userConfig) {
    return (
      <Unauthenticated>
        <Analytics />
        {children}
      </Unauthenticated>
    );
  }

  const onSwitchLocale = (e, {value}) => {
    switchLocale(value, config.authServer);
  };

  const localesMap = config.userConfig.supportedLocales || fallbackLocales;
  const currentTheme = loadTheme(config.userConfig.theme, ecosioColors.themes);

  debug(
    `Loading layout, eventSourceEnabled ${config.userConfig?.eventSourceEnabled}`,
  );
  return (
    <div data-spec="layout" id="account-layout">
      <Analytics />
      <Helmet>
        <html lang={locale} />
      </Helmet>
      {config.userConfig?.eventSourceEnabled && (
        <SessionExpiredDialog
          key="expired-dialog"
          username={config.userConfig.email}
          authServerUrl={config.authServer}
        />
      )}
      <ThemeProvider theme={currentTheme}>
        <Switch>
          <Route path="/invite" render={() => null} />
          <Route path="/saml/onboarding" render={() => null} />
          <Route path="/redirect/saml/onboarding" render={() => null} />
          <Route
            path="/"
            render={() => (
              <NewHeader
                baseUrl={baseUrl}
                userConfig={config.userConfig}
                locale={locale}
                localesMap={localesMap}
                onSwitchLocale={onSwitchLocale}
                authServer={config.authServer}
                isAccounts
                isLandingPage={location.pathname === '/'}
              />
            )}
          />
        </Switch>
        <Switch>
          <SidebarRoute path="/settings" config={config}>
            {children}
          </SidebarRoute>
          <SidebarRoute path="/support" config={config}>
            {children}
          </SidebarRoute>
          <Route path="*" render={() => <Viewport>{children}</Viewport>} />
        </Switch>
      </ThemeProvider>
    </div>
  );
};

LayoutComponent.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.any,
  config: shapes.userConfig,
  baseUrl: PropTypes.string.isRequired,
};

const Layout = withRouter(connect(mapStateToProps)(LayoutComponent));

export default Layout;
