import {CollapseBox, toast} from '@ecosio/components';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {SettingsPageContainer} from '../../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../../SettingsHeader';
import {SettingsFormWrapper} from '../../SettingsStyles';
import SettingsPages from '../../settingsPages';
import {createIdpSettings, handleIdpSaverError} from '../api/samlConfigApi';
import SamlConfigForm from '../components/SamlConfigForm';

const createEmptySamlSettings = () => {
  return {
    domainIdentifiers: [''],
  };
};

const SamlConfigCreatePage = () => {
  const intl = useIntl();
  const history = useHistory();

  const onIdpSettingsSave = (idpSettings) =>
    createIdpSettings(idpSettings)
      .then(() => {
        return new Promise((resolve) => {
          toast({
            title: intl.formatMessage({id: 'SAML_IDP_UPDATE_SUCCESS_TITLE'}),
            description: intl.formatMessage({
              id: 'SAML_IDP_UPDATE_SUCCESS_DESCRIPTION',
            }),
            type: 'success',
            time: 4000,
          });
          history.push('/settings/security');
          resolve();
        });
      })
      .catch((err) => handleIdpSaverError(err, intl));

  return (
    <SettingsPageContainer>
      <SettingsHeader page={SettingsPages.SECURITY}></SettingsHeader>
      <SettingsFormWrapper>
        <CollapseBox
          header={
            <FormattedMessage id="CREATE_SAML_CONFIG_TITLE"></FormattedMessage>
          }
          data-spec="saml-config-col-box">
          <SamlConfigForm
            intl={intl}
            onSubmit={onIdpSettingsSave}
            initialValues={createEmptySamlSettings()}></SamlConfigForm>
        </CollapseBox>
      </SettingsFormWrapper>
    </SettingsPageContainer>
  );
};

SamlConfigCreatePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      providerName: PropTypes.string,
      companyUuid: PropTypes.string,
    }),
  }),
};

export default SamlConfigCreatePage;
