import {toast} from '@ecosio/components';
import axios from 'axios';
import {processValidationResult} from '../../../../helpers/validators';

export const handleIdpSaverError = (error, intl) => {
  // TODO: handle response status 409 with global error for concurrent
  // update of another user
  if (error.response.status === 400 && error.response.data) {
    // if the response doesn't have information on the field that's causing the error,
    // then just show the error message in a dialog
    if (typeof error.response.data === 'string') {
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: error.response.data,
        type: 'error',
      });
      return;
    }
    return processValidationResult(error.response?.data, intl);
  }
  return handleGeneralIdpRequestError(error, intl);
};

export const handleGeneralIdpRequestError = (error, intl) => {
  console.error(error);
  toast({
    title: intl.formatMessage({
      id: 'GENERAL_ERROR',
    }),
    description: error.message,
    type: 'error',
  });
  return error;
};

export const createIdpSettings = async (idpSettings) => {
  return axios.post('/api/saml/idp', idpSettings);
};

export const updateIdpSettings = (idpSettings) => {
  const providerName = idpSettings.providerName;
  delete idpSettings.providerName;
  return axios.put(`/api/saml/idp/${providerName}`, idpSettings);
};

export const fetchSamlAppConfig = async (company) => {
  const response = await axios.get(`/api/saml/app-config/${company}`);
  return response.data;
};

export const fetchSamlIdp = async (providerName, company) => {
  const response = await axios.get(`/api/saml/idp/${company}/${providerName}`);
  return response.data;
};

export const fetchSamlIdps = async (company) => {
  const response = await axios.get(`/api/saml/idp/${company}`);
  return response.data;
};

export const deleteSamlIdp = async (providerName) => {
  await axios.delete(`/api/saml/idp/${providerName}`);
};
