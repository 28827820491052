import {ecosioColors} from '@ecosio/customer-layout';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {throttle} from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Grid, Icon, Input, Loader} from 'semantic-ui-react';
import {styled} from 'styled-components';
import {locationShape} from '../../../../../shapes';
import {stringifyQuery} from '../../../../helpers/utils';
import SubCompanyDropdown from '../../../SubCompanies/SubCompanyDropdown';
import {MainCategory, trackEvent} from '../../../../analytics';
import UserFilterDropdown from './UserFilterDropdown';
import UserOriginDropdown from './UserOriginDropdown';

const USER_STATUS_LIST = [
  {
    translationKey: 'USER_STATUS_ACTIVE',
    urlParam: 'activated',
    urlParamValue: true,
  },
  {
    translationKey: 'USER_STATUS_INACTIVE',
    urlParam: 'activated',
    urlParamValue: false,
  },
];

const USER_INVITE_STATUS_LIST = [
  {
    translationKey: 'USER_INVITE_VALID',
    urlParam: 'expired',
    urlParamValue: false,
  },
  {
    translationKey: 'USER_INVITE_EXPIRED',
    urlParam: 'expired',
    urlParamValue: true,
  },
];

const MFA_STATUS_LIST = [
  {
    translationKey: 'MFA_ENABLED_TOOLTIP',
    urlParam: 'mfaEnabled',
    urlParamValue: true,
  },
  {
    translationKey: 'MFA_DISABLED_TOOLTIP',
    urlParam: 'mfaEnabled',
    urlParamValue: false,
  },
];

const WrapRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: inherit;
  div.column {
    margin: 0.5rem 1rem;
  }
`;

const usePermissions = () => {
  return useQuery(['permissions'], async () => {
    const response = await axios.get('/api/authorities/groups/monitor');
    return response.data;
  });
};

const UserFilter = ({location}) => {
  const history = useHistory();
  const intl = useIntl();

  const params = qs.parse(location.search, {ignoreQueryPrefix: true});
  const company = params.company;

  const [query, setQuery] = useState(params?.query || '');

  const userConfig = useSelector((state) => state.config?.userConfig);
  const configSubCompanies = userConfig?.subCompanies || [];
  const samlConfigEnabled = userConfig?.samlConfigEnabled;
  const hasSubCompanies = configSubCompanies.length > 0;

  const statusOptions = [...USER_INVITE_STATUS_LIST, ...USER_STATUS_LIST];

  const {data: permissions, isLoading: permissionsLoading} = usePermissions();

  const trackAnalytics = (value, param) => {
    const segmentation = {
      mainCategory: MainCategory.FilterClick,
      subCategory: param,
      companyUuid: userConfig?.company?.uuid,
      companyName: userConfig?.company?.name,
    };

    if (value) {
      segmentation.item = value;
    }

    trackEvent('Team user filter', segmentation);
  };

  const changeCompany = (newCompany) => {
    // it is important that in the callback here we use the actual window.location
    // property to parse the current parameters, since the location object
    // from above still carries the "old" location values, and this
    // would lead to the company parameters to "all companies" although
    // one has been selected
    const newParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    delete newParams.page;
    history.push({
      pathname: location.pathname,
      search: stringifyQuery({...newParams, company: newCompany}),
    });
    trackAnalytics(newCompany, 'sub-company');
  };

  const updateSearchQuery = (searchQuery) => {
    const newParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    delete newParams.page;
    history.push({
      pathname: location.pathname,
      search: stringifyQuery({...newParams, query: searchQuery}),
    });
  };

  // https://stackoverflow.com/a/64856090/539144
  const throttledChangeUrl = useRef(
    throttle(updateSearchQuery, 500, {leading: false}),
  );

  const throttledTrackEvent = useRef(throttle(trackAnalytics, 500));

  const queryChange = (event) => {
    setQuery(event.target.value);
    throttledChangeUrl.current(event.target.value);
    throttledTrackEvent.current(undefined, 'text');
  };

  if (permissionsLoading) {
    return <Loader active></Loader>;
  }

  return (
    <Grid data-spec="search-filter">
      <Grid.Row>
        <WrapRow>
          <Grid.Column width={4}>
            <Input
              type="text"
              autoFocus
              style={{width: '100%'}}
              data-spec="search-query"
              value={query}
              placeholder={intl.formatMessage({id: 'USER_FILTER_PLACEHOLDER'})}
              onChange={queryChange}
            />
          </Grid.Column>
          {hasSubCompanies && (
            <Grid.Column width={4}>
              <SubCompanyDropdown
                onChange={changeCompany}
                selection={company}
                style={{width: '100%'}}
              />
            </Grid.Column>
          )}
          <Grid.Column width={2}>
            <UserFilterDropdown
              onChange={trackAnalytics}
              options={permissions.map((p) => ({
                value: p.trKey,
                displayValue: intl.formatMessage({id: p.trKey}),
                urlParam: 'permission',
                urlParamValue: p.uuid,
              }))}
              placeholder="Permissions"
              location={location}
              history={history}
              dataSpec="permissions-dd"
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <UserFilterDropdown
              onChange={trackAnalytics}
              options={statusOptions.map((status) => ({
                ...status,
                value: status.translationKey,
                displayValue: intl.formatMessage({id: status.translationKey}),
              }))}
              placeholder="Status"
              location={location}
              history={history}
              dataSpec="status-dd"
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <UserFilterDropdown
              onChange={trackAnalytics}
              options={MFA_STATUS_LIST.map((mfaStatus) => ({
                ...mfaStatus,
                value: mfaStatus.translationKey,
                displayValue: intl.formatMessage({
                  id: mfaStatus.translationKey,
                }),
              }))}
              placeholder="MFA"
              dataSpec="mfa-dd"
              location={location}
              history={history}
            />
          </Grid.Column>
          {samlConfigEnabled && (
            <Grid.Column width={2}>
              <UserOriginDropdown
                onChange={trackAnalytics}
                companyUuid={userConfig.company.uuid}
                location={location}
                history={history}></UserOriginDropdown>
            </Grid.Column>
          )}
          <Grid.Column width={1} data-spec="f-clear">
            <Icon
              style={{
                marginTop: '10px',
                color: ecosioColors.primaryBlue,
              }}
              name="close"
              link
              onClick={() => {
                setQuery('');
                history.push({search: ''});
              }}
            />
          </Grid.Column>
        </WrapRow>
      </Grid.Row>
    </Grid>
  );
};

UserFilter.propTypes = {
  params: PropTypes.object,
  location: locationShape.isRequired,
};

export default UserFilter;
