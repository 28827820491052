import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';

/** Redirects to  /settings/account if the user doesn't have the appropriate authority*/
export const useRequiresAuthority = (authority) => {
  const history = useHistory();
  const userConfig = useSelector((state) => state.config?.userConfig || {});

  if (userConfig?.authorities && !userConfig.authorities.includes(authority)) {
    history.push('/settings/account');
  }
};
