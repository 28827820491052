import React, {useState} from 'react';
import {CollapseBox, InputField} from '@ecosio/components';
import {Form as FinalForm, Field} from 'react-final-form';
import {Button, Form, Header, Message} from 'semantic-ui-react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {
  Container,
  Img,
  BoxContainer,
  FormContainer,
  PasswordResetMessage,
} from '../Login/ResetPasswordStyles';
import {STATIC_ASSETS_BASE} from '../../constants';
import {
  requiredEmail,
  translateValidationMessage,
} from '../../helpers/validators';
import {createPageTitle} from '../../helpers/helmetHelpers';

const ResendInvitePage = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const intl = useIntl();

  const onSubmit = (values) => {
    setLoading(true);
    const {email} = values;

    // eslint-disable-next-line promise/catch-or-return
    axios
      .post(`/api/resend-invite?email=${encodeURIComponent(email)}`)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        setError(error);
        console.error(`failed to resend invite for user '${email}':`, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <Helmet>
        <title>{createPageTitle(intl, 'RESEND_INVITE_TITLE')}</title>
      </Helmet>

      <CollapseBox style={{margin: 0}}>
        <BoxContainer>
          <FormContainer success={success}>
            {error && (
              <Message error>
                <Message.Header>
                  <FormattedMessage id="GENERAL_ERROR" />
                </Message.Header>
                <p>
                  <FormattedMessage id="RESEND_INVITE_ERROR_CONTENT" />
                </p>
              </Message>
            )}

            {!success && (
              <>
                <Header>
                  <FormattedMessage id="RESEND_INVITE_HEADER" />
                </Header>
                <PasswordResetMessage>
                  <FormattedMessage id="RESEND_INVITE_MESSAGE" />
                </PasswordResetMessage>
              </>
            )}
            {success && (
              <Message success>
                <Message.Header>
                  <FormattedMessage id="RESEND_INVITE_SUCCESS_HEADER" />
                </Message.Header>
                <FormattedMessage id="RESEND_INVITE_SUCCESS_MESSAGE" />
                <p>
                  <Link to="/login">
                    <FormattedMessage id="PW_BACK_TO_LOGIN" />
                  </Link>
                </p>
              </Message>
            )}

            {!success && (
              <FinalForm
                onSubmit={onSubmit}
                render={({handleSubmit, hasValidationErrors, dirty}) => (
                  <Form onSubmit={handleSubmit} style={{margin: '0'}}>
                    <Field
                      autoFocus
                      type="text"
                      component={InputField}
                      validate={(value) =>
                        translateValidationMessage(requiredEmail(value), intl)
                      }
                      name="email"
                      label={<FormattedMessage id="GENERAL_EMAIL" />}
                    />
                    <Button
                      loading={loading}
                      fluid
                      primary
                      type="submit"
                      disabled={hasValidationErrors || !dirty}>
                      <FormattedMessage
                        id="RESEND_INVITE_BUTTON"
                        defaultMessage="Resend mail"
                      />
                    </Button>
                  </Form>
                )}
              />
            )}
          </FormContainer>
          <Img src={`${STATIC_ASSETS_BASE}/assets/password-reset-1.svg`} />
        </BoxContainer>
      </CollapseBox>
    </Container>
  );
};

export default ResendInvitePage;
