import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {Loader} from 'semantic-ui-react';
import {toast} from '@ecosio/components';
import {historyShape, locationShape} from '../../../../../shapes';
import useSamlIdps from '../../SamlConfigPage/hooks/useSamlIdps';
import UserFilterDropdown from './UserFilterDropdown';

const UserOriginDropdown = ({companyUuid, location, history, onChange}) => {
  const intl = useIntl();
  const {isLoading, data: samlIdps, error} = useSamlIdps(companyUuid);

  if (isLoading) {
    return <Loader active></Loader>;
  }

  if (error) {
    if (error.response?.status === 403) {
      return null;
    }
    console.error(error);
    toast({
      title: intl.formatMessage({
        id: 'GENERAL_ERROR',
      }),
      description: error.message,
      type: 'error',
    });
  }

  const options = [
    {
      value: 'ecosio',
      displayValue: 'ecosio',
      urlParam: 'userOrigin',
      urlParamValue: 'ecosio',
    },
    ...samlIdps.map((samlIdp) => ({
      value: `SAML_${samlIdp.providerName}`,
      displayValue: `SAML: ${samlIdp.providerName}`,
      urlParam: 'userOrigin',
      urlParamValue: `SAML_${samlIdp.providerName}`,
    })),
  ];
  return (
    <UserFilterDropdown
      onChange={onChange}
      options={options}
      placeholder={intl.formatMessage({id: 'USER_ORIGIN'})}
      dataSpec="user-origin-dd"
      location={location}
      history={history}
    />
  );
};

UserOriginDropdown.propTypes = {
  companyUuid: PropTypes.string.isRequired,
  history: historyShape.isRequired,
  location: locationShape.isRequired,
  onChange: PropTypes.func,
};

export default UserOriginDropdown;
