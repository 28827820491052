import React from 'react';
import {shapes} from '@ecosio/auth';
import {connect} from 'react-redux';
import {Card, Grid, Image} from 'semantic-ui-react';
import {shouldShowModule} from '@ecosio/customer-layout';
import {FormattedMessage} from 'react-intl';
import logger from '../../../logger';

const debug = logger('default-landingpage');

const styles = {
  wrapper: {marginTop: '25px'},
  image: {height: '200px'},
};

const ModuleTile = ({module}) => {
  const image = module.imageUrl;

  if (typeof image === 'undefined') {
    debug('Unable to load image for module, skipping it', module.name);
    return null;
  }
  const name = module.name.toLowerCase().replace(/_/g, '-');
  const cardId = `module-card-${name}`;

  return (
    <Card href={module.url} id={cardId}>
      <Image src={image} style={styles.image} />
      <Card.Content>
        <Card.Header>
          <FormattedMessage id={`GENERAL_${module.name}`} />
        </Card.Header>
        {/* TODO description needs to be configurable */}
        <Card.Description />
      </Card.Content>
    </Card>
  );
};

ModuleTile.propTypes = {
  module: shapes.module,
};

const ModulesPage = ({userConfig}) => {
  const landingPageModules = userConfig.modules.filter((module) =>
    shouldShowModule(module, userConfig),
  );

  return (
    <Grid
      container
      columns={3}
      style={styles.wrapper}
      className="_si_default_lp">
      <Grid.Row>
        {landingPageModules.map((module, index) => (
          <Grid.Column key={index} style={{marginTop: index > 2 ? 25 : 0}}>
            <ModuleTile module={module} />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

ModulesPage.propTypes = {
  userConfig: shapes.userConfig,
};

const mapStateToProps = ({config}) => ({...config});
export default connect(mapStateToProps)(ModulesPage);
