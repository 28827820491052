import {Authority} from '@ecosio/auth';
import {ecosioColors} from '@ecosio/customer-layout';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Button, Icon, Label, Popup} from 'semantic-ui-react';
import {getPermissionTranslationKey} from '../../../../helpers/translations';
import RequiresAuthority from '../../../RequiresAuthority';
import {Avatar, RelativeDate, isDisabled} from '../Helpers';
import {MainCategory, trackEvent} from '../../../../analytics';

export const Permissions = ({permissions}) => {
  if (permissions?.length === 0) {
    return <FormattedMessage id="USER_REGULAR_USER" />;
  }
  return permissions.map((permission, idx) => (
    <div key={uuidv4()}>
      <FormattedMessage id={getPermissionTranslationKey(permission)} />
      {idx < permissions.length - 1 && ', '}
    </div>
  ));
};

Permissions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const LastLogin = ({timestamp}) => {
  if (timestamp) {
    return <RelativeDate dateString={timestamp} />;
  } else {
    return (
      <Popup
        trigger={<span>N/A</span>}
        content={<FormattedMessage id="LAST_LOGIN_NO_DATA_TOOLTIP" />}
      />
    );
  }
};

LastLogin.propTypes = {
  timestamp: PropTypes.string,
};

// IMPORTANT: the number of columns in the invite table and the
// user table must be equal, and these are the col-widths of each
// column in both tables!
export const cellWidths = [1, 4, 2, 4, 2, 2, 1];

const renderInactiveStatus = () => (
  <Label color="grey">
    <FormattedMessage id="USER_STATUS_INACTIVE" />
  </Label>
);

const generateActiveStatusProps = (user) => {
  // we use a separate style props here because for several customers (coop, tg, ktm)
  // the red Label styling is overwritten in semantic-ui repo
  // https://gitlab.ecosio.com/code/semantic-ui/-/blob/master/src/themes/ecosio-coop/collections/form.overrides#L47-L55
  const redLabelStyle = {
    backgroundColor: ecosioColors.errorRed,
    color: 'white',
  };

  const {samlEnabled, credentialsAuthSupported, totpMfaEnabled} = user;

  if (credentialsAuthSupported && !totpMfaEnabled) {
    return {
      labelColor: 'initial',
      labelStyle: redLabelStyle,
      iconName: 'exclamation circle',
      tooltipMessage: 'MFA_DISABLED_TOOLTIP',
    };
  } else if (!samlEnabled && totpMfaEnabled) {
    return {
      labelColor: 'green',
      labelStyle: {},
      iconName: 'lock',
      tooltipMessage: 'MFA_ENABLED_TOOLTIP',
    };
  } else if (samlEnabled) {
    return {
      labelColor: 'green',
      labelStyle: {},
      iconName: 'users',
      tooltipMessage: 'SAML_USER_TOOLTIP',
    };
  } else {
    return {
      labelColor: 'initial',
      labelStyle: redLabelStyle,
      iconName: 'question',
      tooltipMessage: 'STATUS_RENDERING_FAILED',
    };
  }
};

const renderActiveStatus = (user) => {
  const {labelColor, labelStyle, iconName, tooltipMessage} =
    generateActiveStatusProps(user);
  return (
    <Popup
      trigger={
        <Label color={labelColor} style={labelStyle} data-spec="active-label">
          <Icon name={iconName} />
          <FormattedMessage id="USER_STATUS_ACTIVE" />
        </Label>
      }
      content={
        <FormattedMessage id={tooltipMessage}></FormattedMessage>
      }></Popup>
  );
};

const dataGridConfig = (userConfig) => ({
  idSelector: 'uuid',
  fields: [
    {
      id: 'uuid',
      label: ' ',
      render: (Table, value, row) => (
        <Table.Cell width={cellWidths[0]} textAlign="center">
          <Avatar
            firstName={row.firstName}
            lastName={row.lastName}
            email={row.userId}
            disabled={isDisabled(row)}
          />
        </Table.Cell>
      ),
    },
    {
      id: 'name',
      label: 'GENERAL_NAME',
      render: (Table, value, row) => (
        <Table.Cell width={cellWidths[1]}>
          {row.name && (
            <>
              <strong>{row.name}</strong>
              <br />
            </>
          )}
          {row.userId}
        </Table.Cell>
      ),
    },
    {
      id: 'activated',
      label: 'GENERAL_STATUS',
      render: (Table, value, row) => (
        <Table.Cell width={cellWidths[2]}>
          {row.activated ? renderActiveStatus(row) : renderInactiveStatus()}
        </Table.Cell>
      ),
    },
    {
      id: 'companyName',
      label: 'COMPANY_NAME',
      render: (Table, value) => (
        <Table.Cell width={cellWidths[3]}>{value}</Table.Cell>
      ),
    },
    {
      id: 'permissions',
      label: 'USER_PERMISSIONS',
      render: (Table, permissions) => (
        <Table.Cell width={cellWidths[4]}>
          <Permissions permissions={permissions} />
        </Table.Cell>
      ),
    },
    {
      id: 'lastLogin',
      label: 'LAST_LOGIN_TIME',
      render: (Table, value) => (
        <Table.Cell width={cellWidths[5]}>
          <LastLogin timestamp={value} />
        </Table.Cell>
      ),
    },
    {
      id: 'uuid',
      label: ' ',
      render: (Table, value, row) => {
        const trackAnalytics = () => {
          trackEvent('View user profile', {
            mainCategory: MainCategory.ButtonClick,
            companyUuid: userConfig?.company?.uuid,
            companyName: userConfig?.company?.name,
          });
        };
        return (
          <Table.Cell width={cellWidths[6]} style={{textAlign: 'center'}}>
            <RequiresAuthority requires={Authority.COMPANY_USER_UPDATE}>
              <Button
                style={{pointerEvents: 'auto'}}
                compact
                icon
                as={Link}
                onClick={trackAnalytics}
                to={`/settings/team/${row.userId}`}>
                <Icon name="eye" />
              </Button>
            </RequiresAuthority>
          </Table.Cell>
        );
      },
    },
  ],
});

export default dataGridConfig;
