const SettingsPages = {
  SUPPORT: 'support',
  ACCOUNT: 'account',
  COMPANY: 'company',
  E_INVOICING: 'e-invoicing',
  EMAILS: 'emails',
  // currently actually only filename-pattern page
  ADVANCED: 'advanced',
  NOTIFICATIONS: 'notifications',
  ACCOUNT_SECURITY: 'account-security',
  PASSWORD: 'password',
  TEAM: 'team',
  SECONDARY_EMAILS: 'secondary-emails',
  PARTNER_RELATIONS: 'partner-relations',
  SECURITY: 'security',
  ASSIGNEE_GROUPS: 'assignee-groups',
};

export default SettingsPages;
