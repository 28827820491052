import React from 'react';
import {historyShape} from '../../../../shapes';
import SettingsHeader from '../SettingsHeader';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import SettingsPages from '../settingsPages';
import CompanyForm from './CompanyForm';
import HtmlMessage from '@/components/Helper/HtmlMessage';

const CompanyPage = (props) => {
  const {history} = props;
  return (
    <SettingsPageContainer>
      <SettingsHeader page={SettingsPages.COMPANY} />
      <div className="doc-link">
        <HtmlMessage id="ACCOUNTS_DOCS_COMPANY" />
      </div>
      <CompanyForm history={history} />
    </SettingsPageContainer>
  );
};

CompanyPage.propTypes = {
  history: historyShape.isRequired,
};

export default CompanyPage;
