import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import {shapes} from '@ecosio/auth';
import {toast} from '@ecosio/components';
import {injectIntl} from 'react-intl';

import DynamicTemplate from '../DynamicTemplate/index';
import {dynID} from '../../helpers/utils';
import templates from './Templates/index';

/**
 * Some apps redirect to the accounts app to show a warning.
 * E.g. unsupported browser.
 * Check if this warning is present.
 *
 */
export const urlHasWarningMessageParameter = (query) => {
  return query && query.warning && query.warning.length > 0;
};

/**
 * The app supports a `warning` GET parameter which other apps
 * can set before redirecting to the accounts app.
 * @param query the current location.search query parsed with query-string.parse
 * @param intl
 */
export const showWarningIfPresent = (query, intl, toastFn = toast) => {
  if (!intl) {
    console.warn('No intl prop passed, wont show warning.', query);
    return;
  }

  if (!urlHasWarningMessageParameter(query)) {
    return;
  }

  toastFn({
    title: intl.formatMessage({
      id: 'GENERAL_WARNING',
    }),
    description: intl.formatMessage({
      id: dynID(query.warning),
    }),
    type: 'warning',
  });
};

/**
 * We don't have a proper accounts dashboard yet, so if the user
 * has only one module, we'll redirect to the module right away.
 * @param query the current location.search query parsed with query-string.parse
 * @param modules a collection of active modules
 * @return {boolean}
 */
export const redirectToFirstModuleIfNecessary = (query, modules) => {
  if (
    Array.isArray(modules) &&
    modules.length === 1 &&
    modules[0].url &&
    // we only want to redirect if there is no warning to show
    // if we don't do this: a) the user won't see the warning, b) we will
    // end in a redirect loop
    !urlHasWarningMessageParameter(query)
  ) {
    window.location.href = modules[0].url;
    return true;
  }
  return false;
};

class CustomLandingPage extends React.Component {
  static propTypes = {
    landingPage: PropTypes.string.isRequired,
    location: PropTypes.any,
    userConfig: shapes.userConfig,
    //    intl: intlShape
  };

  componentDidMount() {
    const config = this.props.userConfig;
    if (config) {
      const query = qs.parse(this.props.location.search.substr(1));
      const isRedirect = redirectToFirstModuleIfNecessary(
        query,
        config.modules,
      );
      if (!isRedirect) {
        showWarningIfPresent(query, this.props.intl);
      }
    }
  }

  render() {
    const {landingPage, userConfig} = this.props;
    if (!userConfig) {
      return null;
    }

    return (
      <DynamicTemplate componentName={landingPage} options={{templates}} />
    );
  }
}

export default injectIntl(CustomLandingPage);
