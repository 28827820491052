import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Icon, Popup} from 'semantic-ui-react';
import TogglerFormInput from '../../Team/UserEditPage/TogglerFormInput';

const SamlUsingSubcompaniesToggler = ({usingCompanyMapping}) => {
  return (
    <div style={{marginTop: '2.5rem'}}>
      <TogglerFormInput
        name="usingCompanyMapping"
        valueLabel="USING_SAML_WITH_SUBCOMPANIES_INFO"
        icon={
          <Popup
            trigger={<Icon name="info circle"></Icon>}
            content={
              <FormattedMessage id="USING_SAML_WITH_SUBCOMPANIES_TOOLTIP_INFO"></FormattedMessage>
            }></Popup>
        }
        checked={usingCompanyMapping}></TogglerFormInput>
    </div>
  );
};

SamlUsingSubcompaniesToggler.propTypes = {
  usingCompanyMapping: PropTypes.bool.isRequired,
};

export default SamlUsingSubcompaniesToggler;
