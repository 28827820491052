import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const useHasSubcompanies = (companyUuid) => {
  const subCompanies = useSelector(
    (state) => state.config?.userConfig?.subCompanies,
  );
  const [hasSubcompanies, setHasSubcompanies] = useState(false);

  useEffect(() => {
    if (!companyUuid || !subCompanies || subCompanies.length === 0) {
      setHasSubcompanies(false);
    } else if (subCompanies.length === 1 && subCompanies[0] === companyUuid) {
      setHasSubcompanies(false);
    } else setHasSubcompanies(true);
  }, [companyUuid, subCompanies]);
  return hasSubcompanies;
};

export default useHasSubcompanies;
