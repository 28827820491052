import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';

const MonitorModalActionButtonFooter = ({children}) => {
  return (
    <Grid.Row className="actions">
      <Grid.Column>
        <div className="action-row">{children}</div>
      </Grid.Column>
    </Grid.Row>
  );
};

MonitorModalActionButtonFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MonitorModalActionButtonFooter;
