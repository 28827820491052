import React from 'react';
import PropTypes from 'prop-types';
import {Message, Loader} from 'semantic-ui-react';
import {styled} from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {locationShape} from '../../../shapes';
import {ssoLogin, THIRD_PARTY_COOKIE_DISABLED} from './ssoApi';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`;

export class SsoErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return {error};
  }

  constructor(props) {
    super(props);
    this.state = {error: null};
  }

  componentDidCatch(error, errorInfo) {
    if (error?.name === THIRD_PARTY_COOKIE_DISABLED) {
      console.error(error, error.errorInfo, errorInfo);
    } else {
      console.error(error, errorInfo);
    }
  }

  render() {
    if (this.state.error === null) {
      return this.props.children;
    }
    // https://gitlab.ecosio.com/code/general-issues/-/issues/280
    if (this.state.error?.name === THIRD_PARTY_COOKIE_DISABLED) {
      return (
        <Message floating error data-spec="3pc-error">
          <Message.Header>
            <FormattedMessage id="SSO_THIRD_PARTY_COOKIES_DISABLED_HEAD" />
          </Message.Header>
          <FormattedMessage id="SSO_ERROR_THIRD_PARTY_COOKIES_BODY" />
        </Message>
      );
    } else {
      return (
        <Message floating error data-spec="generic-error">
          <Message.Header>
            <FormattedMessage id="STATUS_EXCEPTION" />
          </Message.Header>
          <FormattedMessage id="SSO_ERROR" />
        </Message>
      );
    }
  }
}

SsoErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * The sole purpose of this component is to initiate a single sign on in the
 * sonepar WebShop as an iframe.
 */
class SingleSignOn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loginStarted: false};
  }

  componentDidMount() {
    if (this.props.config?.authServer) {
      this.startLogin();
    }
  }

  componentDidUpdate() {
    this.startLogin();
  }

  startLogin() {
    if (this.state.loginStarted) {
      return;
    }

    const {location} = this.props;

    this.setState({loginStarted: true}, () => {
      ssoLogin(location)
        .then((res) => {
          // the location header contains the (environment-specific)
          // URL of the monitor masterdata page
          window.location.href = res.headers.location;
        })
        .catch((err) => {
          // we're in async code here, so we need go back to the rendering
          // loop or the error boundary won't catch this.
          // see the test for it in index.test.jsx
          this.setState(() => {
            // rethrow any errors during the login to make the error boundary render
            throw err;
          });
        });
    });
  }

  render() {
    return (
      <Layout>
        <Loader active />
      </Layout>
    );
  }
}

SingleSignOn.propTypes = {
  location: locationShape.isRequired,
  config: PropTypes.any,
};

const mapStateToProps = ({config}) => ({config});

export const ErrorWrappedSingleSignOn = (props) => (
  <SsoErrorBoundary>
    <SingleSignOn {...props} />
  </SsoErrorBoundary>
);

export default connect(mapStateToProps)(ErrorWrappedSingleSignOn);
