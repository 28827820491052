import React from 'react';
import {useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {Loader} from 'semantic-ui-react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {toast} from '@ecosio/components';
import {useIntl} from 'react-intl';
import {processElenaValidationResult} from '../../../../helpers/validators';
import {
  AssigneeGroupAuthority,
  useAssigneeGroup,
  useRedirectIfNoPermission,
} from '../hooks';
import AssigneeGroupForm, {transformToSlug} from './AssigneeGroupForm';

const EditAssigneeGroup = ({match}) => {
  useRedirectIfNoPermission(AssigneeGroupAuthority.EDIT);

  const uuid = match.params.uuid;
  const queryClient = useQueryClient();
  const history = useHistory();
  const query = useAssigneeGroup(uuid);
  const intl = useIntl();

  const onSubmit = async (values) => {
    const body = {
      version: values.version,
      name: transformToSlug(values.name),
      description:
        values.description?.trim() === '' ? undefined : values.description,
      unmanagedMemberUuids: values.members,
      disabled: false,
    };
    try {
      await axios.put(`/api/elena/external-assignee-groups/${uuid}`, body);
      await queryClient.invalidateQueries(['assignee-groups']);
      history.push('/settings/assignee-groups');
    } catch (e) {
      const status = e.response?.status;
      if (status === 400) {
        const errors = processElenaValidationResult(e.response.data);
        errors.members = errors.unmanagedMemberUuids;
        return errors;
      } else if (status === 409) {
        return {
          name: 'A group with this name already exists.',
        };
      } else {
        console.error(e);
        toast({
          type: 'error',
          title: intl.formatMessage({id: 'GENERAL_ERROR'}),
          description: e.message,
        });
      }
    }
  };

  if (query.isLoading) {
    return <Loader active />;
  }

  return (
    <AssigneeGroupForm
      header={{
        id: 'ASSIGNEE_GROUP_EDIT_HEADER',
        values: {group: query.data.name},
      }}
      onSubmit={onSubmit}
      backLink="/settings/assignee-groups"
      mode="edit"
      initialValues={query.data}
    />
  );
};

EditAssigneeGroup.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default EditAssigneeGroup;
