import React from 'react';
import {Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {ErrorContainer} from './LoginStyles';

const SessionExpiredMessage = () => {
  return (
    <ErrorContainer>
      <Message error data-spec="session-expired">
        <Message.Header>
          <FormattedMessage id="SESSION_EXPIRED_HEADER" />
        </Message.Header>
        <Message.Content>
          <FormattedMessage id="SESSION_EXPIRED_FULL" />
        </Message.Content>
      </Message>
    </ErrorContainer>
  );
};

export default SessionExpiredMessage;
