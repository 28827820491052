import {InputField, intlShape} from '@ecosio/components';
import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'react-final-form';
import {FormattedMessage} from 'react-intl';
import {Button, Form, Header, Icon, Popup} from 'semantic-ui-react';
import {FieldArray} from 'react-final-form-arrays';
import {required} from '../../../../helpers/validators';
import './SamlForm.css';

const SamlExternalIdpSettings = ({intl, isEditMode}) => {
  return (
    <>
      <Header>
        <FormattedMessage id="EXTERNAL_IDP_SETTINGS_TITLE"></FormattedMessage>
      </Header>
      <Form.Group>
        <Field
          component={InputField}
          name="providerName"
          label={intl.formatMessage({id: 'PROVIDER_NAME_LABEL'})}
          helpText={<FormattedMessage id="PROVIDER_NAME_HELP" />}
          required
          width={16}
          disabled={isEditMode}
          translateError
          validate={required}
          data-spec="provider-name-field"></Field>
      </Form.Group>
      <FieldArray name="domainIdentifiers">
        {({fields}) => {
          return (
            <>
              <div className="identifier-wrap">
                <div className="identifier-label identifier-box">
                  <div className="identifier-field field">
                    <label>
                      {intl.formatMessage({id: 'DOMAIN_IDENTIFIER_LABEL'})}
                    </label>
                    <Popup
                      content={intl.formatMessage({
                        id: 'DOMAIN_IDENTIFIER_HELP',
                      })}
                      trigger={
                        <Icon
                          name="question circle"
                          style={{cursor: 'pointer'}}
                        />
                      }
                    />
                  </div>
                </div>
                <div className="identifier-button identifier-box">
                  <Button
                    data-spec="add-identifier-mapping"
                    compact
                    onClick={() => fields.push('')}
                    type="button"
                    secondary>
                    <Icon name="plus"></Icon>
                    <FormattedMessage id="ADD_IDENTIFIER"></FormattedMessage>
                  </Button>
                </div>
              </div>

              {fields.map((name, index) => {
                return (
                  <Form.Group
                    key={`${name}_${index}`}
                    className="identifier-form-group">
                    <Field
                      action={
                        fields.value.length <= 1
                          ? undefined
                          : () => {
                              return (
                                <Button
                                  data-spec={`remove-identifier-${index}`}
                                  icon
                                  negative
                                  onClick={() => fields.remove(index)}
                                  type="button">
                                  <Icon name="minus" />
                                </Button>
                              );
                            }
                      }
                      component={InputField}
                      name={name}
                      placeholder={intl.formatMessage({
                        id: 'DOMAIN_IDENTIFIER_LABEL',
                      })}
                      width={16}
                      required
                      translateError
                      validate={required}
                      data-spec={`identifier-field-${index}`}></Field>
                  </Form.Group>
                );
              })}
            </>
          );
        }}
      </FieldArray>

      <Form.Group>
        <Field
          component={InputField}
          name="metadataUrl"
          label={intl.formatMessage({
            id: 'METADATA_URL_LABEL',
          })}
          helpText={<FormattedMessage id="METADATA_URL_HELP" />}
          width={16}
          required
          translateError
          validate={required}
          data-spec="metadata-url-field"></Field>
      </Form.Group>
    </>
  );
};

SamlExternalIdpSettings.propTypes = {
  intl: intlShape.isRequired,
  isEditMode: PropTypes.bool.isRequired,
};

export default SamlExternalIdpSettings;
