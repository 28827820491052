import React, {useState, useEffect} from 'react';
import {Input} from '@ecosio/pathform';
import {useFormState} from 'react-final-form';
import {get} from 'lodash';
import {CompanySettingsConstants} from '../../../../constants';
import {getComplexFieldConfig} from '../CompanyDynamicFields';

//simple component for now, additional complex logic will be added in S2 https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/925
const ComplexDocumentNumber = ({formFieldMap = {}, formEnabled}) => {
  const formState = useFormState();
  const [generateDocumentNumberValue, setGenerateDocumentNumberValue] =
    useState(false);

  useEffect(() => {
    const value = Boolean(
      get(
        formState?.values,
        `complexWebediProperties.${CompanySettingsConstants.SETTING_COMPLEX_DOCUMENT_NUMBER}.generateDocumentNumber`,
        false,
      ),
    );
    setGenerateDocumentNumberValue(value);
  }, [formState?.values]);

  const documentNumberFormFieldConfig = getComplexFieldConfig(
    formFieldMap,
    CompanySettingsConstants.SETTING_COMPLEX_DOCUMENT_NUMBER,
  );

  if (!documentNumberFormFieldConfig) {
    return null;
  }

  return (
    <div style={{flex: '1', paddingTop: '14px'}}>
      <Input
        disabled={!formEnabled}
        data-spec={`company-page-${CompanySettingsConstants.SETTING_COMPLEX_DOCUMENT_NUMBER}`}
        path={CompanySettingsConstants.SETTING_COMPLEX_DOCUMENT_NUMBER}
        name={`complexWebediProperties.${CompanySettingsConstants.SETTING_COMPLEX_DOCUMENT_NUMBER}.generateDocumentNumber`}
        toggle
        onInputChange={() =>
          setGenerateDocumentNumberValue((prevValue) => !prevValue)
        }
        value={generateDocumentNumberValue}
      />
    </div>
  );
};

export default ComplexDocumentNumber;
