import qs from 'querystring';
import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import htmlParse from 'html-react-parser';
import ErrorComponent from '../Helper/ErrorComponent';
import {INVALID_CONFIG} from './LoginPage';

const InvalidConfigMessage = () => {
  const parsed = qs.parse(window.location.search);

  useEffect(() => {
    window.sessionStorage.removeItem(INVALID_CONFIG);
  }, []);

  return (
    <ErrorComponent
      errorContent={
        <FormattedMessage id="GENERAL_INVALID_CONFIG_BODY">
          {(chunks) => htmlParse(chunks.join(''))}
        </FormattedMessage>
      }
      errorHeader={<FormattedMessage id="GENERAL_INVALID_CONFIG" />}
      eventId={parsed?.event}
    />
  );
};

export default InvalidConfigMessage;
