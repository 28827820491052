import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useSamlAllowedCompanies = (rootCompanyUuid) => {
  return useQuery(['samlConfigEnabledCompanies'], async () => {
    const response = await axios.get(`/api/saml/companies/${rootCompanyUuid}`, {
      params: {samlConfigEnabled: true},
    });
    return response.data;
  });
};

export default useSamlAllowedCompanies;
