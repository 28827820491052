import {styled} from 'styled-components';
import {Icon} from 'semantic-ui-react';

export const StyledIcon = styled(Icon)``;

export const Container = styled.div`
  margin: 0 15% 0 15%;
  padding: 32px 0 32px 0;

  @media (max-width: 1110px) {
    margin 0;
    padding: 0;
  }
`;

export const FormContainer = styled.div`
  width: ${(props) => (props.success ? '500px' : '330px')};
  padding: 16px;
`;

export const BoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: 450px;

  @media (max-width: 1110px) {
    height: 600px;
  }
`;

export const PasswordResetMessage = styled.div`
  color: #5c5d5d;
  margin-bottom: 16px;
`;

export const LinkContainer = styled.div`
  margin-top: 20px;
`;

export const Img = styled.img`
  padding: 16px;
  width: 400px;
  height: 300px;
`;

export const Label = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  color: ${(props) => props.theme.secondaryColor};
  margin: 16px 0 12px 0;
`;
