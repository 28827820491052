import React from 'react';
import {Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {ecosioColors} from '@ecosio/customer-layout';
import {v4 as uuidv4} from 'uuid';
import {Pattern, PatternChild, PatternContainer} from './FilePatternStyles';

const Token = ({tokens, onHandleDeleteToken}) => {
  return (
    <>
      {tokens?.map((token, idx) => {
        return (
          <Pattern
            primary
            data-spec={`token_${idx}`}
            className="token"
            key={uuidv4()}
            onClick={() => onHandleDeleteToken(idx)}>
            <PatternChild>{token}</PatternChild>
            <PatternChild>
              <Icon
                name="close"
                style={{
                  color: ecosioColors.greyShades[2],
                  margin: 0,
                  cursor: 'pointer',
                }}
              />
            </PatternChild>
          </Pattern>
        );
      })}
    </>
  );
};

Token.propTypes = {
  onHandleDeleteToken: PropTypes.func.isRequired,
  tokens: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const ActivePattern = ({tokens = [], onHandleDeleteToken}) => (
  <PatternContainer data-spec="token-container">
    <Token tokens={tokens} onHandleDeleteToken={onHandleDeleteToken} />
  </PatternContainer>
);

export default ActivePattern;
