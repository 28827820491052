import React from 'react';
import {Form} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import SubCompanyDropdown from '../../../SubCompanies/SubCompanyDropdown';

const SubCompanyDropdownField = ({input, meta, label, ...rest}) => {
  return (
    <Form.Field>
      <label>
        <FormattedMessage id={label} />
      </label>

      <SubCompanyDropdown
        {...input}
        {...rest}
        selection={input.value}
        onChange={input.onChange}
      />
    </Form.Field>
  );
};

export default SubCompanyDropdownField;
