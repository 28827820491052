import React from 'react';
import axios from 'axios';
import {FormattedMessage} from 'react-intl';
import {Button} from 'semantic-ui-react';

import {Header} from '../Invite/InviteLayout';

const SsoTermsOfService = () => {
  const onAccept = async () => {
    try {
      const response = await axios.post('/api/sso/terms-of-service');
      window.location.href = response.headers.location;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Header>
        <FormattedMessage id="SONEPAR_TOS_HEADER" />
      </Header>
      <p>
        <FormattedMessage id="SONEPAR_TOS_BODY" />
      </p>

      <Button onClick={onAccept}>
        <FormattedMessage id="SONEPAR_TOS_ACCEPT" />
      </Button>
    </>
  );
};

export default SsoTermsOfService;
