import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {
  ContentBox,
  ContinueButton,
  Header,
  HeaderImage,
  InfoParagraph,
  Layout,
  TextContainer,
} from '../Invite/InviteLayout';

const ConcurrentSessionError = () => {
  return (
    <Layout>
      <ContentBox>
        <div data-spec="concurrent-session-page">
          <HeaderImage url="eco_welcome.svg" />
          <TextContainer>
            <Header>
              <FormattedMessage id="CONCURRENT_SESSION" />
            </Header>
            <InfoParagraph>
              <FormattedMessage id="CONCURRENT_SESSION_INFO" />
            </InfoParagraph>
          </TextContainer>
          <Link to="/login">
            <ContinueButton data-spec="login-button">
              <FormattedMessage id="CONCURRENT_SESSION_TO_LOGIN" />
              <Icon name="caret right" />
            </ContinueButton>
          </Link>
        </div>
      </ContentBox>
    </Layout>
  );
};

export default ConcurrentSessionError;
