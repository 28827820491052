import {configureStore, createSlice} from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {isOpen: false},
  reducers: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    },
  },
});

const mfaSettingsSlice = createSlice({
  name: 'mfaSettings',
  initialState: {
    totpEnabled: false,
    version: null,
    uuid: null,
    mfaEnforcedOnCompany: false,
  },
  reducers: {
    enableTotp(state) {
      state.totpEnabled = true;
    },
    disableTotp(state) {
      state.totpEnabled = false;
    },
    toggleTotp(state) {
      state.totpEnabled = !state.totpEnabled;
    },
    setTotpSettings(state, action) {
      state.totpEnabled = action.payload.totpEnabled;
      state.version = action.payload.version;
      state.uuid = action.payload.uuid;
    },
    setMfaEnforcedOnCompany(state, action) {
      state.mfaEnforcedOnCompany = action.payload;
    },
  },
});

export const modalActions = modalSlice.actions;
export const mfaSettingsActions = mfaSettingsSlice.actions;

const mfaStore = configureStore({
  reducer: {modal: modalSlice.reducer, mfaSettings: mfaSettingsSlice.reducer},
});

export default mfaStore;
