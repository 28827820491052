import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

export const loadCsrfToken = () => {
  let url = '/api/csrf';

  // invite routes need their dedicated csrf token
  if (window.location.pathname.indexOf('/account/invite') === 0) {
    url = '/api/invite/csrf';
  } else if (window.location.pathname.indexOf('/account/sso') === 0) {
    url = '/api/sso/csrf';
  }
  return axios.get(url);
};

export const useCsrfToken = () => {
  return useQuery(['csrf-token'], loadCsrfToken, {
    staleTime: Infinity,
  });
};
