import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Grid} from 'semantic-ui-react';
import {isEmpty, get} from 'lodash';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Input} from '@ecosio/pathform';
import {toast} from '@ecosio/components';
import {dynID} from '../../../helpers/utils';
import ComplexCountryOfOrigin from './WebediEnrichmentComplexComponents/ComplexCountryOfOrigin';
import ComplexDocumentNumber from './WebediEnrichmentComplexComponents/ComplexDocumentNumber';

export const getComplexFieldConfig = (formFieldMap = {}, targetPath) => {
  return get(formFieldMap, targetPath, undefined);
};

const mapStateToProps = (state) => ({
  usingCompany: !state.config?.userConfig?.offeringCompany,
});

class CompanyDynamicFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {scenarioListWithFields: []};
  }

  async componentDidMount() {
    await this.fetchScenarioListWithFields();
  }

  updateDynamicFields() {
    const {scenarioListWithFields} = this.state;

    scenarioListWithFields.forEach((value) =>
      this.props.updateFormFields(value.formFieldMap),
    );
  }

  async fetchScenarioListWithFields() {
    const {usingCompany, intl} = this.props;

    //fetch data only for usingCompanies
    if (usingCompany) {
      try {
        const response = await axios.get(
          `/api/webedi/ext/company/scenarioListWithFields`,
        );

        this.setState({scenarioListWithFields: response?.data}, () =>
          //callback the parentfunction to update the formFields with dynamic fields
          this.updateDynamicFields(),
        );
      } catch (error) {
        toast({
          title: intl.formatMessage({id: 'COMPANY_DYNAMIC_FIELDS_ERROR'}),
          description: error.message,
          type: 'error',
          time: 100 * 1000,
        });
        console.error(error);
      }
    }
  }

  dropDownOptions = (options, intl) =>
    options.map((value) => ({
      text: intl.formatMessage({id: value.text}),
      value: value.value,
      key: value.value,
    }));

  render() {
    const {intl, usingCompany, formEnabled} = this.props;
    //should only work for usingCompanyUser
    if (!usingCompany) {
      return '';
    }

    const {scenarioListWithFields} = this.state;

    return (
      <Grid>
        {scenarioListWithFields
          .filter((value) => !isEmpty(value.formFieldMap))
          .map((value, key) => {
            return (
              <Fragment key={key}>
                <h1>
                  {intl.formatMessage({id: dynID(value.enrichmentHeading)})}
                </h1>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <ComplexDocumentNumber
                      formFieldMap={value?.formFieldMap}
                      formEnabled={formEnabled}
                    />
                  </Grid.Column>
                  {Object.keys(value.formFieldMap)
                    .filter((d) => !value.formFieldMap[d]?.complexClassType)
                    .map((field, idx) => {
                      const formField = value.formFieldMap[field];
                      return (
                        <Grid.Column width={8} key={idx}>
                          <Input
                            key={idx}
                            translateError
                            data-spec={`company-page-${field}`}
                            path={field}
                            multiple={formField?.multiSelect}
                            name={`webEdiProperties.${field}`}
                            required={formField.input.required}
                          />
                        </Grid.Column>
                      );
                    })}

                  <Grid.Column width={16}>
                    <ComplexCountryOfOrigin
                      formFieldMap={value?.formFieldMap}
                      formEnabled={formEnabled}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Fragment>
            );
          })}
      </Grid>
    );
  }
}

CompanyDynamicFields.propTypes = {
  usingCompany: PropTypes.bool.isRequired,
  updateFormFields: PropTypes.func.isRequired,
  formEnabled: PropTypes.bool.isRequired,
};

CompanyDynamicFields.defaultProps = {
  formEnabled: true,
};

const CompanyDynamicFieldsWithRedux =
  connect(mapStateToProps)(CompanyDynamicFields);
export default injectIntl(CompanyDynamicFieldsWithRedux);
