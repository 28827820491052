import {InputField, intlShape} from '@ecosio/components';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {Form, Header, Icon} from 'semantic-ui-react';
import {stringifyQuery} from '../../helpers/utils';
import {ContentBox, InfoParagraph, Layout} from '../Invite/InviteLayout';
import {updateUserDetais} from '../Settings/Team/UserEditPage/userEditClient';
import {NextButton, samlOnboardingLayout} from './styledComponents';
import useRedirectOnboardedUser from './useRedirectOnboardedUser';

const SamlProfileSetupPage = ({match}) => {
  const intl = useIntl();
  const [user, setUser] = useState(null);
  const history = useHistory();
  const {email} = match.params;

  useRedirectOnboardedUser();

  useEffect(() => {
    const fetchUser = async () => {
      const userResponse = await axios.get(`/api/user/${email}`);
      setUser(userResponse.data);
    };

    fetchUser();
  }, [email]);

  const onSubmit = async (formData) => {
    const valuesChanged =
      formData.firstName !== user.firstName ||
      formData.lastName !== user.lastName;
    if (valuesChanged) {
      await updateUserDetais(user.userId, formData);
    }
    history.push({
      pathname: '/saml/onboarding/success',
      search: stringifyQuery({
        name: `${formData.firstName} ${formData.lastName}`,
        saml: true,
      }),
    });
  };

  return (
    <Layout style={samlOnboardingLayout}>
      <ContentBox width="421px">
        <FinalForm
          onSubmit={onSubmit}
          initialValues={user}
          render={({handleSubmit}) => {
            return (
              <Form onSubmit={handleSubmit} id="user-profile-form">
                <div style={{padding: '2rem 2rem 0', marginBottom: '220px'}}>
                  <Header as="h1">
                    <FormattedMessage id="PROFILE_SETUP_HEADER" />
                  </Header>
                  <InfoParagraph>
                    <FormattedMessage id="PROFILE_SETUP_SAML_PAGE_PARAGRAPH" />
                  </InfoParagraph>
                  <Form.Group style={{width: '100%'}}>
                    <Field
                      component={InputField}
                      name="firstName"
                      label={intl.formatMessage({id: 'GENERAL_FIRST_NAME'})}
                      width={8}
                      required></Field>
                    <Field
                      component={InputField}
                      name="lastName"
                      label={intl.formatMessage({id: 'GENERAL_LAST_NAME'})}
                      width={8}
                      required
                    />
                  </Form.Group>
                  <Form.Group style={{width: '100%'}}>
                    <Field
                      component={InputField}
                      type="text"
                      name="userId"
                      disabled
                      label={intl.formatMessage({id: 'GENERAL_EMAIL'})}
                      width={16}
                      required
                    />
                  </Form.Group>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <NextButton disabled={!user} data-spec="submitFormButton">
                    <FormattedMessage id="GENERAL_NEXT" />
                    <Icon name="caret right" />
                  </NextButton>
                </div>
              </Form>
            );
          }}></FinalForm>
      </ContentBox>
    </Layout>
  );
};

SamlProfileSetupPage.propTypes = {
  intl: intlShape.isRequired,
  match: PropTypes.object,
};

export default SamlProfileSetupPage;
