import Countly from 'countly-sdk-web';
import {APP_ENV, determineEnvironmentFromLocation} from '@ecosio/components';
import {CompanyTypes, CONTEXT_PATH} from './constants';
import {isProd} from './helpers/utils';

const app = 'monitor';
let disabled = true;
const IGNORED_EMAILS = ['@mailtest.ecosio.com', '@simulator.amazones.com'];

export const MainCategory = {
  ElementClick: 'Element click',
  ButtonClick: 'Button click',
  FilterClick: 'Filter click',
  InputField: 'Input field',
};

const getRevision = () => {
  try {
    return process.env.REVISION;
  } catch (error) {
    console.error(error);
    return 'Unknown';
  }
};

const shouldDisableAnalyticsForUser = (userConfig = {}) => {
  if (
    // we don't want to count e2e test users, but still need to e2e-test countly :)
    // we have a single user that works for tests, all others do not send events
    typeof IGNORED_EMAILS.find(
      (ignored) => userConfig.email.indexOf(ignored) > -1,
    ) !== 'undefined' &&
    userConfig.email !== 'countly+eurotrade@mailtest.ecosio.com'
  ) {
    // don't track e2e test users
    return true;
  }

  //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/1023
  if (
    userConfig?.company?.companyType === CompanyTypes.EXTERNAL &&
    !userConfig?.termsOfServiceAccepted
  ) {
    // don't track users, if the company type is EXTERNAL and terms of service are not approved
    return true;
  }

  return false;
};

export const setupSdk = (userConfig) => {
  try {
    if (shouldDisableAnalyticsForUser(userConfig)) {
      return false;
    }

    const env = determineEnvironmentFromLocation(window.location.href);

    if (env === APP_ENV.DEV || env === APP_ENV.DOCKER) {
      return false;
    }

    disabled = false;

    let app_key = '';
    let url = '';

    switch (env) {
      case APP_ENV.PROD:
        app_key = '398d8379b5277e2b18f9c8493064b8682b7b76f8';
        url = 'https://ecosio-c7ab9d997f488.flex.countly.com';
        break;
      case APP_ENV.TEST:
        app_key = '709990d4e47ab9a335d44436d5dd5dfc1aed2f56';
        url = 'https://ecosio-c7ab9d997f488.flex.countly.com';
        break;
      default:
        app_key = '3f24138f99de74b60376122286cfd44050e565a8';
        url = 'https://ecosio-27eebab1ffca9.flex.countly.com';
        break;
    }

    // eslint-disable-next-line camelcase
    Countly.init({
      app_key,
      url,
      debug: false,
      // eslint-disable-next-line camelcase
      device_id: userConfig.email,
      // eslint-disable-next-line camelcase
      clear_stored_id: true,
    });
    Countly.track_errors({version: getRevision()});
    Countly.track_sessions();
    trackUserDetails(userConfig);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const trackUserDetails = (userConfig) => {
  if (disabled || !isProd()) {
    return;
  }

  try {
    Countly.q.push([
      'user_details',
      {
        name: `${userConfig.firstName} ${userConfig.lastName}`,
        username: userConfig.email,
        email: userConfig.email,
        organization: userConfig.company.name,
        custom: {
          selectedSubCompanyName: userConfig.selectedSubCompanyName,
          includeSubCompanies: userConfig.includeSubCompanies,
        },
      },
    ]);
  } catch (error) {
    console.error(error);
  }
};

export const trackPageView = (location, companyUuid, companyName) => {
  if (disabled || !isProd()) {
    return;
  }

  // https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript#h_01HABTQ437CAD08ESRK6RMJ2FG
  try {
    Countly.q.push(
      ['track_pageview', `${CONTEXT_PATH}${location?.pathname}`],
      null,
      {
        segmentation: {
          env: determineEnvironmentFromLocation(window?.location?.href),
          companyUuid,
          companyName,
          app,
        },
      },
    );
  } catch (error) {
    console.error(error);
  }
};

export const trackEvent = (name, segmentation) => {
  console.info('Track event: ', name, segmentation);

  if (disabled || !isProd()) {
    return;
  }

  try {
    const {companyUuid, companyName, ...rest} = segmentation;
    Countly.q.push([
      'add_event',
      {
        key: name,
        count: 1,
        segmentation: Object.assign(
          {
            // nameFormat: segmentation.nameFormat,
            env: determineEnvironmentFromLocation(window?.location?.href),
            companyUuid,
            companyName,
            app,
          },
          rest,
        ),
      },
    ]);
  } catch (error) {
    console.error(error);
  }
};
