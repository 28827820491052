import {
  configureAxios,
  setupAxiosInterceptors as setupAxios,
  setupAxiosDefaults,
  getXSRFCookieNameForEnvironment,
} from '@ecosio/auth';
import {
  determineEnvironmentFromLocation,
  ErrorBoundary,
} from '@ecosio/components';
import {ConnectedIntlProvider} from '@ecosio/locales';
import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import axiosRetry, {exponentialDelay, isNetworkError} from 'axios-retry';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import React from 'react';
import {Provider} from 'react-redux';
import AppRouter, {allowedPaths} from './AppRouter';
import store from './store';

import logger from './logger';

const debug = logger('App');
const queryClient = new QueryClient();

const BASE_URL = '/account';

const accountsErrorHandler = (error) => {
  if (
    error?.response?.status === 403 &&
    error?.response?.headers?.mfa_enforced
  ) {
    return Promise.resolve(error);
  }
  return Promise.reject(error);
};

// axios breaks stack traces https://github.com/axios/axios/issues/2387
// this is fixed in 1.0.0-alpha ... https://github.com/axios/axios/releases/tag/v1.0.0-alpha.1
// should be removed once 1.0.0 is out
// https://github.com/axios/axios/pull/4624
axiosBetterStacktrace(axios);
setupAxios(store, allowedPaths, BASE_URL);
setupAxiosDefaults(axios);

const XSRF_HEADER = 'X-XSRF-TOKEN';
const csrfCookieName = getXSRFCookieNameForEnvironment();
const environment = determineEnvironmentFromLocation(window.location.href);

debug(`Setting csrf cookie name for ${environment} env to ${csrfCookieName}`);

configureAxios((authAxios) => {
  authAxios.defaults.baseURL = BASE_URL;
  authAxios.defaults.xsrfCookieName = csrfCookieName;
});

// Because our axios interceptor in the auth library treats 403 errors as any other client errors,
// we need to override the interceptor here and check whether 403 occured due to MFA enforced.
// In this case we don't treat the response as an error.
axios.interceptors.response.use((response) => response, accountsErrorHandler);

axios.defaults.baseURL = BASE_URL;
axios.defaults.xsrfCookieName = csrfCookieName;
axios.defaults.xsrfHeaderName = XSRF_HEADER;

// retry axios requests on network error
axiosRetry(axios, {
  retries: 3,
  retryDelay: exponentialDelay,
  retryCondition: isNetworkError,
});

const App = () => (
  <Provider store={store}>
    <ConnectedIntlProvider>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary
          exceptionLogger={(error) => console.error(error)}
          axios={axios}>
          <AppRouter store={store} baseUrl={BASE_URL} />
        </ErrorBoundary>
      </QueryClientProvider>
    </ConnectedIntlProvider>
  </Provider>
);

export default App;
