import {styled} from 'styled-components';

/**
 * SettingsPages FormFields wrapper
 * This wrapper is for the FormFields in ReactFinalForm to achieve Responsiveness
 */
export const FormFieldsWrapper = styled.div`
  max-width: 700px;
  margin: 0px auto 64px auto;
`;
