import {InputField, intlShape} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import PropTypes from 'prop-types';
import React from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {ButtonGroup, Form, Header, Modal} from 'semantic-ui-react';
import {styled} from 'styled-components';
import {required} from '../../../../../helpers/validators';
import {
  ArrowButton,
  CancelButton,
  CancelIcon,
  CheckmarkButton,
} from '../../commons/styledComponents';
import {modalActions} from '../mfaStore';

const MfaModalHeader = styled.div`
  padding: 1rem;
  margin: 1rem;
`;

const Description = styled.div`
  padding: 0rem 1rem;
  margin: 1rem;
  color: #5c5d5d;
`;

const MfaModalOutput = styled.div`
  padding: 0rem 1rem;
  margin: 2rem 1rem;
  display: flex;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
`;

/**
 * Layout component for the pages of the MfaSetupModal
 * @param {*} props
 * @returns MfaSetupModalPage
 */
const MfaSetupModalPage = ({intl, title, description, output, formOptions}) => {
  const dispatch = useDispatch();

  return (
    <Modal.Content style={{padding: 0}}>
      <CancelIcon onClick={() => dispatch(modalActions.close())}></CancelIcon>
      <MfaModalHeader>
        <Header as="h2">
          {title && <FormattedMessage id={title}></FormattedMessage>}
        </Header>
      </MfaModalHeader>
      <Description
        style={{
          marginBottom: output ? 'initial' : '2rem',
        }}>
        <FormattedMessage id={description}></FormattedMessage>
      </Description>

      {output && <MfaModalOutput>{output}</MfaModalOutput>}

      <FinalForm
        onSubmit={formOptions.onSubmit}
        render={({handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group
              style={{
                backgroundColor: ecosioColors.greyShades[5],
                padding: '0.7rem 1.8rem 1.7rem 1.8rem',
                margin: '0',
              }}>
              <Field
                translateError
                component={InputField}
                type={formOptions.input.type}
                name={formOptions.input.name}
                data-spec={`${formOptions.input.name}-input`}
                label={intl.formatMessage({id: formOptions.input.label})}
                required
                validate={required}
                width={16}
              />
            </Form.Group>
            <Footer>
              <ButtonGroup style={{width: '70%'}}>
                <CancelButton
                  onClick={() => dispatch(modalActions.close())}></CancelButton>
                {formOptions.submitBtn?.isFinish ? (
                  <CheckmarkButton
                    id={formOptions.submitBtn.id}></CheckmarkButton>
                ) : (
                  <ArrowButton
                    id="GENERAL_NEXT_STEP"
                    dataSpec="next-btn"></ArrowButton>
                )}
              </ButtonGroup>
            </Footer>
          </Form>
        )}></FinalForm>
    </Modal.Content>
  );
};

MfaSetupModalPage.propTypes = {
  intl: intlShape.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  output: PropTypes.node,
  formOptions: PropTypes.shape({
    onSubmit: PropTypes.func,
    submitBtn: PropTypes.shape({
      id: PropTypes.string,
      isFinish: PropTypes.bool,
    }),
    input: PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
};

export default MfaSetupModalPage;
