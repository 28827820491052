import React, {useEffect, useState} from 'react';
import {
  InputField,
  InternalError,
  PageNotFound,
  toast,
} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import {styled} from 'styled-components';
import {FormattedMessage, useIntl} from 'react-intl';
import {Field, Form as FinalForm} from 'react-final-form';
import {Button, Form, Grid, Header, Icon} from 'semantic-ui-react';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import {InputArray} from '@ecosio/pathform';
import arrayMutators from 'final-form-arrays';
import {useHistory} from 'react-router-dom';
import {
  processValidationResult,
  requiredEmail,
} from '../../../helpers/validators';
import SettingsHeader from '../SettingsHeader';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import {STATIC_ASSETS_BASE} from '../../../constants';
import SettingsPages from '../settingsPages';
import {createPageTitle} from '../../../helpers/helmetHelpers';
import {MainCategory, trackEvent} from '../../../analytics';
import {useUserConfig} from '../../../hooks/useUserConfig';
import OurSupport from './OurSupport';

export const HeaderSubHeader = styled.div`
  width: 508px;
  color: #5c5d5d;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`;

const ImageContainer = styled.div`
  @media (min-width: 0px) and (max-width: 1200px) {
    display: none;
  }
`;

const SupportPage = () => {
  const [disableButton, setDisableButton] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [companySupportDetails, setCompanySupportDetails] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const userConfig = useUserConfig();

  const fetchCompany = async () => {
    try {
      const response = await axios.get(`/api/company/support-email`);

      if (
        response &&
        response.data &&
        (!response.data.ediSupportEmails ||
          response.data.ediSupportEmails.length === 0)
      ) {
        response.data = {...response.data, ediSupportEmails: ['']};
      }

      setCompanySupportDetails(response?.data);
    } catch (error) {
      setErrorCode(error.response.status);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  const handleNetworkError = (error, intl) => {
    if (error.response.status === 409) {
      toast({
        title: intl.formatMessage({
          id: 'SETTINGS_CONCURRENT_UPDATE_TITLE',
        }),
        description: intl.formatMessage({
          id: 'SETTINGS_CONCURRENT_UPDATE_DESCRIPTION',
        }),
        type: 'warning',
        time: 100 * 1000,
      });
    } else {
      console.error(error);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: error.message,
        type: 'error',
        time: 100 * 1000,
      });
    }
  };

  const handleResult = (res, intl) => {
    if (res.status === 200) {
      toast({
        title: intl.formatMessage({
          id: 'COMPANY_SUPPORT_EMAIL_UPDATE_SUCCESS_TITLE',
        }),
        description: intl.formatMessage({
          id: 'COMPANY_SUPPORT_EMAIL_UPDATE_SUCCESS_DESCRIPTION',
        }),
        type: 'success',
        time: 4000,
      });
      fetchCompany();
    }
  };

  const trackAnalytics = (event) => {
    trackEvent(event, {
      mainCategory: MainCategory.ButtonClick,
      companyUuid: userConfig?.company?.uuid,
      companyName: userConfig?.company?.name,
    });
  };

  const onSubmit = async (values) => {
    setDisableButton(true);
    return await axios
      .post(`/api/company/support-email`, values)
      .then((res) => {
        setDisableButton(false);
        handleResult(res, intl);
      })
      .catch((e) => {
        if (e.response?.status === 400) {
          setDisableButton(false);
          return processValidationResult(e.response?.data, intl);
        } else {
          setDisableButton(false);
          handleNetworkError(e, intl);
        }
      });
  };

  const initialValues = companySupportDetails;

  if (errorCode) {
    if (errorCode === 404) {
      return <PageNotFound history={history} />;
    }

    if (errorCode === 500) {
      return <InternalError />;
    }
  }
  return (
    <div>
      <SettingsPageContainer>
        <SettingsHeader page={SettingsPages.SUPPORT} />
        <Helmet>
          <title>{createPageTitle(intl, 'SUPPORT_PAGE_TITLE')}</title>
        </Helmet>
        <Grid>
          <Grid.Row
            style={{
              backgroundColor: `${ecosioColors.greyShades[5]}`,
            }}>
            <Grid.Column width={2} />
            <Grid.Column width={7} verticalAlign="middle">
              <Header as="h2">
                <Header.Content>
                  <FormattedMessage id="YOUR_SUPPORT_CONTACT_HEADER" />
                  <Header.Subheader>
                    <HeaderSubHeader>
                      <FormattedMessage id="YOUR_SUPPORT_CONTACT_SUBHEADER" />
                    </HeaderSubHeader>
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <FinalForm
                mutators={{...arrayMutators}}
                initialValues={initialValues}
                onSubmit={onSubmit}
                render={({handleSubmit}) => (
                  <Form onSubmit={handleSubmit}>
                    <InputArray name="ediSupportEmails">
                      {({fields}) => (
                        <div style={{flex: '1'}}>
                          {fields.map((name, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                marginTop: '5px',
                              }}>
                              <div style={{flex: '1'}}>
                                <Field
                                  component={InputField}
                                  translateError
                                  path="ediSupportEmails"
                                  data-spec="support-page-ediSupportEmail"
                                  name={name}
                                  validate={requiredEmail}
                                />
                              </div>
                              <Button
                                style={{
                                  maxHeight: '38px',
                                }}
                                disabled={index === 0}
                                icon
                                negative
                                type="button"
                                onClick={() => {
                                  fields.remove(index);
                                  trackAnalytics('Delete EDI support email');
                                }}>
                                <Icon name="delete" />
                              </Button>
                            </div>
                          ))}
                          <div
                            style={{
                              marginTop: '10px',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <Button
                              onClick={() => {
                                fields.push('');
                                trackAnalytics('Add EDI support email');
                              }}
                              positive>
                              <Icon name="add" />
                              &nbsp;
                              <FormattedMessage
                                defaultMessage="add edi support email"
                                id="GENERAL_ADD_EDI_SUPPORT_EMAIL"
                              />
                            </Button>
                            <Button
                              type="submit"
                              disabled={disableButton}
                              loading={disableButton}>
                              <Icon name="checkmark" />
                              <FormattedMessage id="GENERAL_SUBMIT" />
                            </Button>
                          </div>
                          <br />
                        </div>
                      )}
                    </InputArray>
                  </Form>
                )}
              />
            </Grid.Column>
            <Grid.Column width={7} verticalAlign="middle" textAlign="center">
              <ImageContainer>
                <img
                  src={`${STATIC_ASSETS_BASE}/assets/support_ecosio.svg`}
                  height="241px"
                  width="299px"
                  alt="Placeholder"
                />
              </ImageContainer>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={{
              height: '271px',
              marginTop: '24px',
            }}>
            <OurSupport />
          </Grid.Row>
        </Grid>
      </SettingsPageContainer>
    </div>
  );
};

export default SupportPage;
