import {styled} from 'styled-components';
import {Button} from 'semantic-ui-react';
import {ecosioColors} from '@ecosio/customer-layout';

export const Layout = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url('https://static.ecosio.com/accounts/assets/eco_illu_bg.svg'),
    linear-gradient(112deg, rgba(255, 255, 255, 0) 50%, #e5edff 50%),
    url('https://assets.ecosio.com/98.0.0-beta.15/monitor/themes/monitor/assets/images/Logo.png');
  background-repeat: no-repeat;
  background-position:
    bottom left,
    right,
    top 80px left 110px;
  background-size:
    auto,
    contain,
    147px auto;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 48px -5px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width || 'auto'};
  background-color: white;
`;

export const ContinueButton = styled(Button)`
  margin: 0px !important;
  align-self: flex-end;
  width: ${(props) => props.size || 'auto'};
  span {
    margin-right: 1rem;
  }
`;

export const Header = styled.h1`
  color: ${ecosioColors.secondaryBlue};
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div`
  color: ${ecosioColors.primaryBlue};
  font-size: 16px;
`;

export const HeaderImage = styled.div`
  width: 421px;
  height: 225px;
  background-image: url('https://static.ecosio.com/accounts/assets/${(props) =>
    props.url}');
  background-repeat: no-repeat;
  background-position: ${(props) => props.position || 'right'};
  margin-bottom: 3rem;
`;

export const TextContainer = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
`;

export const InfoParagraph = styled.p`
  max-width: calc(421px - 4rem);
  margin-top: 1.5rem;
  line-height: 22px;
`;
