import axios from 'axios';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {toast} from '@ecosio/components';

export const COMPANY_API_URL = '/api/company';
/**
 * Custom hook to fetch the company the currently logged in user belongs to
 * @param shouldFetch
 * @returns {{company: unknown, loading: boolean}}
 */
const useCompany = (shouldFetch = true) => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    if (shouldFetch) {
      axios
        .get(COMPANY_API_URL)
        .then((res) => {
          setCompany(res.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('failed to fetch company settings', error);
          toast({
            title: intl.formatMessage({
              id: 'GENERAL_ERROR',
            }),
            description: error.message,
            type: 'error',
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [intl, shouldFetch]);

  return {company, loading};
};

export default useCompany;
