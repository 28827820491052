import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Image} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {LocaleAnchor} from '@ecosio/components';

const styles = {
  itemsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  itemContainer: {
    width: '280px',
    marginRight: '50px',
  },
  boxContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  big: {
    marginTop: '50px',
    backgroundColor: '#9C383F',
    width: '280px',
    height: '280px',
  },
  img: {
    backgroundColor: '#D2D2D2',
    width: '130px',
    height: '130px',
  },
  headline: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '7px',
    marginBottom: '20px',
    fontSize: '120%',
  },
  text: {
    textAlign: 'center',
    fontSize: '12px',
  },
  inactive: {
    opacity: 0.2,
  },
};

const mapStateToProps = ({config}) => ({
  config,
});

let Box = injectIntl(({data, config}) => {
  const {modules} = config.userConfig;
  let supplyModule;
  modules.map((item) =>
    data.supplyModule === item.name ? (supplyModule = item.name) : null,
  );
  const style = !supplyModule
    ? Object.assign({}, styles.img, styles.inactive)
    : styles.img;
  const img = !supplyModule ? (
    <Image src={data.img} fluid />
  ) : (
    <LocaleAnchor
      id={`${data.supplyModule.toLowerCase()}_module`}
      url={getBaseUrl(config.servers, data)}
      style={{display: 'inline-block'}}>
      <Image src={data.img} fluid />
    </LocaleAnchor>
  );
  return (
    <Fragment>
      <div style={styles.tiny}>
        <div style={style}>{img}</div>
        <div style={styles.text}>{data.label}</div>
      </div>
    </Fragment>
  );
});

Box.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        img: PropTypes.string,
        link: PropTypes.any,
      }),
    ),
  }),
};

Box = connect(mapStateToProps)(Box);

const Item = ({data}) => {
  return (
    <div style={styles.itemContainer}>
      <div style={styles.big}>
        <Image src={data.main} fluid />
      </div>
      <div style={styles.headline}>{data.label}</div>

      <div style={styles.boxContainer}>
        <Box data={data.items[0]} />
        {data.items[1] ? <Box data={data.items[1]} /> : null}
      </div>

      <div style={styles.boxContainer}>
        {data.items[2] ? <Box data={data.items[2]} /> : null}
        {data.items[3] ? <Box data={data.items[3]} /> : null}
      </div>
      <div style={styles.boxContainer}>
        {data.items[4] ? <Box data={data.items[4]} /> : null}
      </div>
    </div>
  );
};

Item.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.object,
    main: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.object,
        img: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }),
};

const getBaseUrl = (configServers, data) => {
  const path = data.link;
  const server = data.server;
  const result = Object.entries(configServers).find(([key]) => {
    return key === server;
  });
  if (result) {
    return `${result[1]}/${path}`;
  } else {
    console.warn(`BASEURL path for ${server} not found!`);
    return null;
  }
};

const data = [
  {
    label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_DELIVERY_MANAGEMENT" />,
    main: 'https://assets.ecosio.com/images/horsch/suppliermanagement_red.png',
    items: [
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_NEW_LSA" />,
        img: 'https://assets.ecosio.com/images/horsch/ssa_grey.png',
        link: 'suppliers/list?firstLevel=NEW',
        supplyModule: 'SUPPLIER_DATABASE',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
      {
        label: (
          <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_SUPPLIER_PERFORMANCE" />
        ),
        img: 'https://assets.ecosio.com/images/horsch/supplierperformance_grey.png',
        link: 'suppliers/list/all',
        supplyModule: 'SUPPLIER_PERFORMANCE',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_ISSUE_TRACKER" />,
        img: 'https://assets.ecosio.com/images/horsch/measures_grey.png',
        link: 'issues?issueType=FREE_FORM',
        supplyModule: 'ISSUE_TRACKER',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_MEETING_NOTES" />,
        img: 'https://assets.ecosio.com/images/horsch/notes_grey.png',
        supplyModule: null,
        link: null,
        server: null,
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDING_IDEAS" />,
        img: 'https://assets.ecosio.com/images/horsch/idea_grey.png',
        link: 'ideas?issueType=IDEAS',
        supplyModule: 'IDEAS',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
    ],
  },
  {
    label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_QUALITY" />,
    main: 'https://assets.ecosio.com/images/horsch/quality_red.png',
    items: [
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_RECLAMATION" />,
        img: 'https://assets.ecosio.com/images/horsch/claims_grey.png',
        supplyModule: 'QUALITY_ASSURANCE',
        link: 'quality-assurance?issueType=COMPLAINT&status=OPEN',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_SPECIAL_RELEASE" />,
        img: 'https://assets.ecosio.com/images/horsch/notes_grey.png',
        supplyModule: 'QUALITY_ASSURANCE',
        link: 'quality-assurance?issueType=SPECIAL_RELEASE&status=OPEN',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_AUDITS" />,
        img: 'https://assets.ecosio.com/images/horsch/audits_grey.png',
        supplyModule: null,
        link: null,
        server: null,
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_PRIOR_SAMPLING" />,
        img: 'https://assets.ecosio.com/images/horsch/sampeling_grey.png',
        supplyModule: 'QUALITY_ASSURANCE',
        link: 'quality-assurance?issueType=ARTICLE_INSPECTION&status=OPEN',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
    ],
  },
  {
    label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_DEMAND_MANAGEMENT" />,
    main: 'https://assets.ecosio.com/images/horsch/demandmanagement_red.png',
    items: [
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_DEMAND_PREVIEW" />,
        img: 'https://assets.ecosio.com/images/horsch/demandpreview_grey.png',
        supplyModule: 'DEMAND_PREVIEW',
        link: 'demandpreview',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_WEB_EDI" />,
        img: 'https://assets.ecosio.com/images/horsch/webedi_grey.png',
        supplyModule: 'WEB_EDI',
        link: '',
        server: 'FE_CONFIG_WEBEDI_SERVER',
      },
      {
        label: (
          <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_STORAGE_SITUATION" />
        ),
        img: 'https://assets.ecosio.com/images/horsch/stockmanagement_grey.png',
        supplyModule: null,
        link: null,
        server: null,
      },
    ],
  },
  {
    label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_DOCUMENT_SHARE" />,
    main: 'https://assets.ecosio.com/images/horsch/documentmanagement_red.png',
    items: [
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_DRAWINGS" />,
        img: 'https://assets.ecosio.com/images/horsch/drawings_grey.png',
        supplyModule: 'DOCUMENT_MANAGEMENT',
        link: 'documents/drawings/list',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
      {
        label: <FormattedMessage id="CUSTOM_HORSCH_LANDINNG_DOCUMENTS" />,
        img: 'https://assets.ecosio.com/images/horsch/standards_grey.png',
        supplyModule: 'DOCUMENT_MANAGEMENT',
        link: 'documents/general/list',
        server: 'FE_CONFIG_SUPPLY_SERVER',
      },
    ],
  },
];

const Template = () => {
  return (
    <div style={styles.itemsContainer} className="_sie_horsch">
      <Item data={data[0]} />
      <Item data={data[1]} />
      <Item data={data[2]} />
      <Item data={data[3]} />
    </div>
  );
};

export default Template;
