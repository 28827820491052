import PropTypes from 'prop-types';
import axios from 'axios';

export const FILENAME_PATTERN_URI = `/api/company/filename-pattern`;

/**
 * Backend returns a filenamePatternShape
 */
export const getFilenamePattern = () => {
  return axios.get(FILENAME_PATTERN_URI);
};

export const filenamePatternShape = PropTypes.shape({
  keywords: PropTypes.arrayOf(PropTypes.string),
  filenamePattern: PropTypes.string,
});
