import {styled} from 'styled-components';
import {ecosioColors} from '@ecosio/customer-layout';

export const Pattern = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  font-size: 12px;
  font-height: 15px;
  font-weight: bold;
  border-radius: 2rem;
  border: 1px solid
    ${(props) => (props.primary ? `${props.theme.primaryColor}` : 'white')};
  margin: 4px;
  background: white;
`;

export const PatternChild = styled.div`
  padding: 6px 8px 6px 8px;
`;

export const PatternContainer = styled.div`
  border-left: 1px solid ${ecosioColors.successGreen};
  background: white;
  padding: 16px;
  min-height: 74px;
  width: 100%;
`;

export const SettingsPageContainer = styled.div`
  padding: ${({fullWidth}) => {
    return fullWidth ? `20px 0px 0px 0px` : `20px 38px 20px 38px`;
  }};
`;
