import {InternalError, PageNotFound, toast} from '@ecosio/components';
import {useQuery} from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Button, Icon, Loader} from 'semantic-ui-react';
import {styled} from 'styled-components';
import Label from '../../../Layout/Label';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import {fetchSamlIdps} from '../../SamlConfigPage/api/samlConfigApi';
import StatusSummaryCollapseBox, {
  SettingContainer,
} from '../../commons/StatusSummaryCollapseBox';

const LabelIcon = styled(Icon)`
  color: ${(props) => props.theme.primaryColor};
`;

const EditIcon = styled(Icon)`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
`;

const Tag = styled.span`
  border: 1.5px solid ${(props) => props.theme.primaryColor};
  border-radius: 2rem;
  padding: 0.2rem 0.7rem;
  font-size: 0.9rem;
  display: inline-block;
`;

const IdpLabel = styled.div`
  * {
    margin-right: 1rem !important;
  }
`;

const SamlItem = ({index, samlIdp, history}) => {
  const noOfSubcompanies = samlIdp.subcompanyNames.length;
  return (
    <div className="saml-item">
      <SettingContainer key={index} data-spec={`saml-idp-${index}`}>
        <div>
          <IdpLabel>
            <LabelIcon name="users"></LabelIcon>
            <span data-spec="idp-provider-name">{samlIdp.providerName}</span>
            <Tag data-spec="root-company">{samlIdp.rootCompanyName}</Tag>
            {noOfSubcompanies > 0 && (
              <Tag data-spec="number-of-subcompanies">
                {noOfSubcompanies +
                  (noOfSubcompanies > 1 ? ' companies' : ' company')}
              </Tag>
            )}
          </IdpLabel>
        </div>
        <EditIcon
          data-spec="view-idp-icon"
          name="pencil"
          onClick={() =>
            history.push(
              `/settings/saml-config/${samlIdp.rootCompanyUuid}/${samlIdp.providerName}`,
            )
          }
        />
      </SettingContainer>
    </div>
  );
};

SamlItem.propTypes = {
  index: PropTypes.number,
  samlIdp: PropTypes.shape({
    providerName: PropTypes.string,
    rootCompanyUuid: PropTypes.string,
    rootCompanyName: PropTypes.string,
    subcompanyNames: PropTypes.arrayOf(PropTypes.string),
  }),
};

const SamlItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .saml-item {
    width: 49%;
  }
`;

const SamlCollapseBoxContent = styled.div`
  margin: 1rem 2rem;
`;

const SamlCollapseBox = () => {
  const history = useHistory();
  const intl = useIntl();

  const companyUuid = useSelector(
    (state) => state.config?.userConfig?.company?.uuid,
  );

  const {
    isLoading,
    data: samlIdps,
    isFetching,
    error,
  } = useQuery(
    ['samlIdps'],
    async () => {
      return await fetchSamlIdps(companyUuid);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const showLoader = isLoading || isFetching;

  if (showLoader) {
    return <Loader active></Loader>;
  }
  if (error) {
    if (error.response?.status === 404) {
      return <PageNotFound history={history} />;
    } else if (error?.response?.status === 403) {
      toast({
        title: intl.formatMessage({id: '403_UNAUTHORIZED'}),
        description: intl.formatMessage({
          id: '403_UNAUTHORIZED_DESCRIPTION',
        }),
        type: 'error',
        time: 4000,
      });
      return history.goBack();
    } else {
      return <InternalError history={history} />;
    }
  }
  return (
    <StatusSummaryCollapseBox
      style={{marginBottom: '2rem'}}
      headerId="IDP_SETUP_TITLE"
      dataSpec="saml-col-box"
      checked={samlIdps && samlIdps.length > 0}
      summaryMsgId={
        samlIdps && samlIdps.length > 0 ? 'ACTIVATED' : 'DISABLED_STATUS'
      }>
      <SamlCollapseBoxContent>
        <InfoMessageWrapper>
          <FormattedMessage id="IDP_SETUP_DESCRIPTION"></FormattedMessage>
        </InfoMessageWrapper>
        {samlIdps && samlIdps.length > 0 ? (
          <>
            <Label messageId="SAML_CONFIGURATIONS_LABEL"></Label>
            <SamlItemsWrapper>
              {samlIdps.map((samlIdp, index) => (
                <SamlItem
                  key={index}
                  samlIdp={samlIdp}
                  index={index}
                  history={history}></SamlItem>
              ))}
            </SamlItemsWrapper>
          </>
        ) : (
          <SettingContainer data-spec="no-saml-idp">
            <FormattedMessage id="NO_SAML_IDP"></FormattedMessage>
          </SettingContainer>
        )}
        <Button
          as={Link}
          to="/settings/saml-config/create"
          data-spec="add-new-idp-button">
          <Icon name="plus" style={{marginRight: '0.7rem'}}></Icon>
          <FormattedMessage id="ADD_SAML_IDP_BUTTON"></FormattedMessage>
        </Button>
      </SamlCollapseBoxContent>
    </StatusSummaryCollapseBox>
  );
};

export default SamlCollapseBox;
