/* eslint-disable promise/no-return-wrap */
import axios from 'axios';

export const ssoBase = '/api/sso';
export const thirdPartyCookie = '3pc';

export const THIRD_PARTY_COOKIE_DISABLED = 'ThirdPartyCookieDisabledError';

class CookieBlockedError extends Error {
  constructor(message, errorInfo) {
    super(message);
    this.name = THIRD_PARTY_COOKIE_DISABLED;
    this.errorInfo = errorInfo || {};
  }
}

/**
 * Thrown if we are fairly sure it's because the user blocked third party cookies.
 */
export class ThirdPartyCookieBlockedError extends CookieBlockedError {}

/**
 * Throw if we can read the test cookie, but it's cause is likely an application
 * problem.
 */
export class UnexpectedThirdPartyCookieBlockedError extends CookieBlockedError {
  constructor(message, errorInfo) {
    super(message, errorInfo);
    this.reportToSentry = true;
  }
}

const parseCookie = (str) => {
  try {
    return str
      .split(';')
      .map((v) => v.split('='))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {});
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const testFor3rdPartyCookies = () => {
  const rndm = // nosemgrep not security relevant
    Math.random().toString(36).substring(2, 15) + // nosemgrep not security relevant
    Math.random().toString(36).substring(2, 15); // nosemgrep not security relevant

  return (
    axios
      // we handle all errors in the then block here
      .get(`${ssoBase}/3pc/${rndm}`, {validateStatus: () => true})
      .then((result) => {
        if (!result?.status) {
          return Promise.reject(
            new UnexpectedThirdPartyCookieBlockedError(
              'Response did not have a status',
              result,
            ),
          );
        }

        if (result.status > 209) {
          // TODO check for 50x and retry
          return Promise.reject(
            new UnexpectedThirdPartyCookieBlockedError(
              `Cookie response status was ${result.status}`,
              result,
            ),
          );
        }

        const cookie = parseCookie(document.cookie);

        if (!cookie || !cookie[thirdPartyCookie]) {
          return Promise.reject(
            new ThirdPartyCookieBlockedError(
              `Unable to parse cookie, received '${JSON.stringify(
                cookie,
              )}' in set-cookie header`,
              document.cookie,
            ),
          );
        }

        const cookieValue = cookie[thirdPartyCookie];

        if (cookieValue !== rndm) {
          return Promise.reject(
            new ThirdPartyCookieBlockedError(
              `Cookie value did not match expected value. Expected ${rndm}, received ${cookieValue}`,
              result.headers,
            ),
          );
        }
        return cookieValue;
      })
      .catch((err) => Promise.reject(err))
  );
};

/**
 * @param location
 * @returns {Promise<AxiosResponse<any>>}
 */
export const ssoLogin = async (location) => {
  if (!location || !location.search) {
    return Promise.reject(
      new Error(
        'Unable to send login request without location or location.search',
      ),
    );
  }

  try {
    // first let's see if we can get a cookie
    // https://gitlab.ecosio.com/code/general-issues/-/issues/280
    await testFor3rdPartyCookies();

    return axios.post(`${ssoBase}/login${location.search}`).catch((e) => {
      if (!e.response?.status) {
        return Promise.reject(
          `SSO login failed, error did not have a response status ${JSON.stringify(
            e,
          )}`,
        );
      }
      if (e.response.status === 401 || e.response.status === 403) {
        return Promise.reject(new Error('Sonepar SSO authentication error'));

        // Don't send status code 400 errors to Sentry (is only ever sent when
        // there's a conflict with company aliases during login)
      } else if (e.response.status !== 400) {
        console.error(e);
      }
      return Promise.reject(e);
    });
  } catch (e) {
    return Promise.reject(e);
  }
};
