import React, {useState} from 'react';
import qs from 'qs';
import axios from 'axios';
import {Button, Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {CollapseBox, PageNotFound} from '@ecosio/components';
import {styled} from 'styled-components';

import {stringifyQuery} from '../../../helpers/utils';
import {historyShape, locationShape} from '../../../../shapes';

const CenteredCollapseBox = styled(CollapseBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerifyEmail = ({location, history}) => {
  const [error, setError] = useState(null);
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});

  // Don't show this page when no toke is provided
  if (!query.token) {
    return <PageNotFound history={history} />;
  }

  const onVerifyEmail = async () => {
    try {
      await axios.post(
        `/api/user/emails/verify?${stringifyQuery({token: query.token})}`,
      );

      history.push({
        pathname: '/settings/emails',
        state: {emailVerified: true},
      });
    } catch (err) {
      // todo differentiate between errors
      setError(err);
    }
  };

  return (
    <>
      {error && (
        <Message floating error>
          <Message.Header>
            <FormattedMessage id="GENERAL_ERROR" />
          </Message.Header>
          <p>{error.message}</p>
        </Message>
      )}

      <CenteredCollapseBox>
        <p>
          <FormattedMessage id="VERIFY_EMAIL_INFO" />
        </p>

        <Button onClick={onVerifyEmail}>
          <FormattedMessage id="VERIFY_EMAIL_BUTTON" />
        </Button>
      </CenteredCollapseBox>
    </>
  );
};

VerifyEmail.propTypes = {
  location: locationShape.isRequired,
  history: historyShape.isRequired,
};

export default VerifyEmail;
