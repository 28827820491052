import {FormattedRelativeTime} from 'react-intl';
import React from 'react';
import {selectUnit} from '@formatjs/intl-utils';
import {Popup} from 'semantic-ui-react';
import {FormatDateTime} from '@ecosio/components';

const RelativeTime = ({value}) => {
  if (typeof value !== 'string') {
    return null;
  }

  const {value: diff, unit} = selectUnit(new Date(value));

  return (
    <Popup
      content={<FormatDateTime dateString={value} stripTimezone={false} />}
      trigger={
        <time dateTime={value}>
          <FormattedRelativeTime value={diff} numeric="auto" unit={unit} />
        </time>
      }
    />
  );
};

export default RelativeTime;
