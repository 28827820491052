import React from 'react';
import {Message} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {ErrorContainer} from './LoginStyles';

const PasswordChangedMessage = () => {
  return (
    <ErrorContainer>
      <Message info data-spec="session-expired">
        <Message.Header>
          <FormattedMessage id="PASSWORD_CHANGED_HEADER" />
        </Message.Header>
        <Message.Content>
          <FormattedMessage id="PASSWORD_CHANGED_FULL" />
        </Message.Content>
      </Message>
    </ErrorContainer>
  );
};

export default PasswordChangedMessage;
