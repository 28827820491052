import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Header, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {
  ContentBox,
  HeaderImage,
  InfoParagraph,
  Layout,
  Subtitle,
  TextContainer,
} from '../Invite/InviteLayout';
import {NextButton, samlOnboardingLayout} from './styledComponents';
import useRedirectOnboardedUser from './useRedirectOnboardedUser';

const SamlWelcomePage = ({match}) => {
  const {email} = match.params;

  useRedirectOnboardedUser();

  return (
    <Layout style={samlOnboardingLayout}>
      <ContentBox>
        <HeaderImage url="eco_welcome.svg" style={{width: 'auto'}} />

        <TextContainer>
          <Header as="h1">
            <FormattedMessage id="WELCOME_PAGE_HEADER" />
          </Header>
          <Subtitle>{email}</Subtitle>
          <InfoParagraph>
            <FormattedMessage id="WELCOME_SAML_PAGE_PARAGRAPH" />
          </InfoParagraph>
        </TextContainer>

        <NextButton
          forwardedAs={Link}
          to={`/saml/onboarding/profile-setup/${email}`}
          data-spec="start-button">
          <FormattedMessage id="WELCOME_PAGE_CONTINUE" />
          <Icon name="caret right" />
        </NextButton>
      </ContentBox>
    </Layout>
  );
};

SamlWelcomePage.propTypes = {
  match: PropTypes.object,
};

export default SamlWelcomePage;
