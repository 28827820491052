import {FormattedMessage} from 'react-intl';
import htmlParse from 'html-react-parser';
import React from 'react';

const HtmlMessage = ({id}) => (
  <FormattedMessage id={id}>
    {(chunks) => htmlParse(chunks.join(''))}
  </FormattedMessage>
);

export default HtmlMessage;
