import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  userConfig: state.config?.userConfig,
});

const RequiresAuthority = ({requires, userConfig, children}) => {
  const authorities = userConfig?.authorities || [];
  if (!requires || authorities.includes(requires)) {
    return children;
  } else {
    return null;
  }
};

RequiresAuthority.propTypes = {
  requires: PropTypes.string,
  userConfig: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default connect(mapStateToProps)(RequiresAuthority);
