import {RATE_LIMIT_PATH, useConfig} from '@ecosio/auth';
import {
  InternalError,
  PageNotFound,
  RateLimitExceeded,
  ToastContainer,
  toast,
} from '@ecosio/components';
import axios from 'axios';
import {createBrowserHistory} from 'history';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {Route, Redirect, Router, Switch} from 'react-router-dom';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import {Container, Header, Loader} from 'semantic-ui-react';
import {styled} from 'styled-components';
import {locationShape} from '../shapes';
import LandingPage from './components/CustomLandingPage';
import Layout from './components/Layout/index';
import LoginPage from './components/Login/LoginPage';
import RequestPasswordReset from './components/Login/RequestPasswordReset';
import SamlLoginPage from './components/Login/SamlLoginPage';
import LogoutPage from './components/Logout/LogoutPage';
import ResendInvitePage from './components/ResendInvitePage/ResendInvitePage';
import SamlProfileSetupPage from './components/SamlOnboarding/SamlProfileSetupPage';
import SamlSuccessPage from './components/SamlOnboarding/SamlSuccessPage';
import SamlWelcomePage from './components/SamlOnboarding/SamlWelcomePage';
import AccountPage from './components/Settings/AccountPage/AccountPage';
import CompanyPage from './components/Settings/CompanyPage/CompanyPage';
import EmailsPage from './components/Settings/Emails/EmailsPage';
import VerifyEmail from './components/Settings/Emails/VerifyEmail';
import FileNamePatternPage from './components/Settings/FileNamePatternPage/FileNamePatternPage';
import SupportSettingsPage from './components/Settings/SupportPage/SupportPage';
import {CONTEXT_PATH} from './constants';

import SuccessPage from './components/Invite/SuccessPage';
import WelcomePage from './components/Invite/WelcomePage';
import EInvoicePage from './components/Settings/EInvoicePage/EInvoicePage';
import SubscriptionSettings from './components/Settings/Notifications/SubscriptionSettings';
import PartnerRelations from './components/Settings/PartnerRelations/PartnerRelations';
import SamlConfigCreatePage from './components/Settings/SamlConfigPage/pages/SamlConfigCreatePage';
import SamlConfigUpdatePage from './components/Settings/SamlConfigPage/pages/SamlConfigUpdatePage';
import SecurityPage from './components/Settings/SecurityPage/SecurityPage';
import TeamPage from './components/Settings/Team/TeamPage/TeamPage';
import UserEditPage from './components/Settings/Team/UserEditPage/UserEditPage';
import UserSecurityPage from './components/Settings/UserSecurityPage/UserSecurityPage';
import SingleSignOn from './components/SingleSignOn';
import ConcurrentSessionError from './components/SingleSignOn/ConcurrentSessionError';
import SsoTermsOfService from './components/SingleSignOn/SsoTermsOfService';
import featureFlagClient from './featureflags/FeatureFlagProvider';
import {isProd} from './helpers/utils';
import logger from './logger';
import {useCsrfToken} from './hooks/csrfToken';
import AssigneeGroupsPage from './components/Settings/AssigneeGroups/AssigneeGroupsPage';
import CreateAssigneeGroup from './components/Settings/AssigneeGroups/AssigneeGroupForm/CreateAssigneeGroup';
import EditAssigneeGroup from './components/Settings/AssigneeGroups/AssigneeGroupForm/EditAssigneeGroup';
import {setupSdk, trackPageView, trackUserDetails} from './analytics';

const debug = logger('AppRouter');
export const history = createBrowserHistory({basename: CONTEXT_PATH});

/* zxcvbn is a large library, used in all pages containing password fields */
const PasswordResetPageLazy = React.lazy(
  () => import('./components/Settings/PasswordResetPage/PasswordResetPage'),
);

// reset password settings page
const PasswordResetPage = (props) => (
  <React.Suspense fallback={<Loader active />}>
    <PasswordResetPageLazy {...props} />
  </React.Suspense>
);

const ResetPasswordLazy = React.lazy(
  () => import('./components/Login/ResetPassword'),
);

// reset page after requesting a password reset
const ResetPassword = (props) => (
  <React.Suspense fallback={<Loader />}>
    <ResetPasswordLazy {...props} />
  </React.Suspense>
);

const ProfileSetupLazy = React.lazy(
  () => import('./components/Invite/ProfileSetup'),
);

// password setting while going through invite
const ProfileSetup = (props) => (
  <React.Suspense fallback={<Loader />}>
    <ProfileSetupLazy {...props} />
  </React.Suspense>
);

export const PASSWORD_RESET_PATH = '/passwordreset';

const OauthRedirectPage = () => {
  useEffect(() => {
    const redirectPath = window.sessionStorage.getItem('redirectPath');
    debug(`SessionStorage redirectPath ${redirectPath}`);
    const isLogout = redirectPath?.includes('logout');
    const finalPathAndSearchString =
      isLogout || !redirectPath ? '/' : redirectPath;

    const finalRedirectUrl = window.location.origin + finalPathAndSearchString;
    debug(`Redirecting to ${finalRedirectUrl}`);
    window.location.assign(finalRedirectUrl);
  });

  return null;
};

const ErrorWrapper = styled(Container)`
  width: 100%;
  margin-top: 2.5rem;
`;

const ConfigError = ({error}) => {
  if (isProd()) {
    return <InternalError />;
  } else {
    return (
      <ErrorWrapper>
        <Header as="h1" size="large">
          Configuration error (in development mode)
        </Header>
        <p>
          <strong>Error message:</strong> {error.message}
        </p>
        <strong>Stack trace:</strong>
        <pre>
          {error.stack.split('\n').map((frame, idx) => (
            <span style={{display: 'block'}} key={idx}>
              {frame}
            </span>
          ))}
        </pre>
      </ErrorWrapper>
    );
  }
};

export const allowedPaths = [
  `${CONTEXT_PATH}/login`,
  `${CONTEXT_PATH}/login/saml`,
  `${CONTEXT_PATH}/logout`,
  `${CONTEXT_PATH}/request-password-reset`,
  `${CONTEXT_PATH}${PASSWORD_RESET_PATH}`,
  `${CONTEXT_PATH}/invite`,
  `${CONTEXT_PATH}/sso`,
  `${CONTEXT_PATH}/concurrent-session-error`,
  `${CONTEXT_PATH}/rate-limit-exceeded`,
  `${CONTEXT_PATH}/resend-invite`,
];

const USER_SECURITY_PATH = '/settings/account-security';

const RoutesGuardWrapper = ({children}) => {
  const location = useLocation();
  const history = useHistory();
  const mfaEnforcedOnUser = useSelector(
    (state) => state.config?.userConfig?.mfaEnforcedOnUser,
  );
  const intl = useIntl();

  useEffect(() => {
    if (mfaEnforcedOnUser && USER_SECURITY_PATH !== location.pathname) {
      history.push(USER_SECURITY_PATH);
      toast({
        title: intl.formatMessage({
          id: 'MFA_REQUIRED_TITLE',
        }),
        description: intl.formatMessage({
          id: 'MFA_REQUIRED_DESCRIPTION',
        }),
        type: 'warning',
        time: 10 * 1000,
      });
    }
  }, [history, intl, location, mfaEnforcedOnUser]);

  return <>{children}</>;
};

RoutesGuardWrapper.propTypes = {children: PropTypes.node};

const CountlyWrapper = ({location, userConfig}) => {
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

  useEffect(() => {
    if (!analyticsInitialized) {
      return;
    }
    trackPageView(
      location,
      userConfig?.company?.uuid,
      userConfig?.company?.name,
    );
  }, [analyticsInitialized, location, userConfig]);

  useEffect(() => {
    if (!userConfig?.email || analyticsInitialized) {
      return;
    }
    console.info('Setup countly SDK now.');
    setupSdk(userConfig);
    trackUserDetails(userConfig);
    setAnalyticsInitialized(true);
  }, [userConfig, analyticsInitialized]);

  return null;
};

CountlyWrapper.propTypes = {
  location: locationShape,
  userConfig: PropTypes.object,
};

const parseModules = (rawString) => {
  try {
    if (Array.isArray(rawString)) {
      return rawString;
    }

    if (typeof rawString === 'undefined') {
      return [];
    }

    return JSON.parse(rawString);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const loadCustomConfig = async () => {
  const result = await axios.get('/api/config');
  let enabledModules = '';
  let ticketingEnabled = false;
  try {
    const [modules, monitorTicketingEnabled] = await Promise.all([
      featureFlagClient.getStringFlag('account.enabled_modules', '[]'),
      featureFlagClient.getBooleanFlag('monitor.ticket_details', false),
    ]);

    ticketingEnabled = monitorTicketingEnabled.value ?? false;
    enabledModules = modules?.value ?? '[]';
  } catch (error) {
    console.error(error);
  }

  return Object.assign(result.data, {
    // TODO: the enabledModules should be loaded by the customer-layout itself
    //  asynchronously and be decoupled from the userConfig
    modules: parseModules(enabledModules),
    ticketingEnabled,
  });
};

const AppRouter = () => {
  const {loading, config, configError} = useConfig({
    allowedPaths,
    loadCustomConfig,
  });

  const userConfig = config.userConfig;
  const landingPageTemplate = get(userConfig, 'landingPage', 'DEFAULT');

  // https://docs.spring.io/spring-security/reference/5.8/migration/servlet/exploits.html#_i_am_using_a_single_page_application_with_httpsessioncsrftokenrepository
  const {isLoading} = useCsrfToken();

  if (loading || isLoading) {
    return <Loader active />;
  }

  if (configError) {
    console.error(configError);
    return <ConfigError error={configError} />;
  }

  // ***********************************************************************
  //         PLEASE READ THIS BEFORE ADDING NEW ROUTES !!!!!!              *
  // * If you add a new route with a new path at the beginning, make sure  *
  // * to add this path in AccountsApiSecurityConfig.java                  *
  // * INDEX_HTML_PATH_VARIANTS constant, to make the backend in k8s serve *
  // * the index.html for that frontend route.                             *
  // ***********************************************************************
  return (
    <Router history={history}>
      <Layout baseUrl={CONTEXT_PATH}>
        <ToastContainer position="top-center" onlyShowLast dataSpec="toast" />
        <CountlyWrapper location={location} userConfig={config.userConfig} />
        <RoutesGuardWrapper>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <LandingPage
                  userConfig={userConfig}
                  landingPage={landingPageTemplate}
                  {...props}
                />
              )}
            />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/login/saml" component={SamlLoginPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path={RATE_LIMIT_PATH} component={RateLimitExceeded} />
            <Route
              exact
              path="/request-password-reset"
              component={RequestPasswordReset}
            />
            {/* Be VERY careful when changing this URL */}
            {/* There are emails generated in the admin using it. */}
            <Route
              path={`${PASSWORD_RESET_PATH}/:hash?`}
              component={ResetPassword}
            />
            <Route exact path="/settings">
              <Redirect to="/settings/account" />
            </Route>
            <Route exact path="/settings/company" component={CompanyPage} />
            <Route exact path="/settings/account" component={AccountPage} />
            <Route exact path="/settings/emails" component={EmailsPage} />
            <Route
              exact
              path="/settings/advanced"
              component={FileNamePatternPage}
            />
            <Route
              exact
              path="/settings/saml-config/create"
              component={SamlConfigCreatePage}></Route>
            <Route
              exact
              path="/settings/saml-config/:companyUuid/:providerName"
              component={SamlConfigUpdatePage}></Route>
            <Route
              exact
              path="/settings/e-invoicing"
              component={EInvoicePage}
            />
            <Route exact path="/verify-email" component={VerifyEmail} />
            <Route
              exact
              path="/settings/support"
              component={SupportSettingsPage}
            />
            <Route
              exact
              path="/settings/password"
              component={PasswordResetPage}
            />
            {/* To fix dead links in old notification emails */}
            <Route exact path="/settings/subscriptions">
              <Redirect to="/settings/notifications" />
            </Route>
            <Route
              exact
              path="/settings/notifications"
              component={SubscriptionSettings}
            />
            <Route
              exact
              path={USER_SECURITY_PATH}
              component={UserSecurityPage}
            />
            <Route
              exact
              path="/settings/partner-relations"
              component={PartnerRelations}
            />
            <Route exact path="/settings/security" component={SecurityPage} />
            {/* TODO restrict access per custom route component to only users that have the right permission */}
            <Route exact path="/settings/team" component={TeamPage} />
            <Route
              exact
              path="/settings/assignee-groups"
              component={AssigneeGroupsPage}
            />
            <Route
              exact
              path="/settings/assignee-groups/create"
              component={CreateAssigneeGroup}
            />
            <Route
              exact
              path="/settings/assignee-groups/:uuid/edit"
              component={EditAssigneeGroup}
            />
            <Route
              exact
              path="/settings/team/:userId"
              component={UserEditPage}
            />
            <Route
              exact
              path="/concurrent-session-error"
              component={ConcurrentSessionError}
            />
            <Route exact path="/invite/welcome" component={WelcomePage} />
            <Route
              exact
              path="/invite/profile-setup"
              component={ProfileSetup}
            />
            <Route exact path="/invite/success" component={SuccessPage} />
            <Route exact path="/sso" component={SingleSignOn} />
            <Route
              exact
              path="/sso/terms-of-service"
              component={SsoTermsOfService}
            />

            <Route exact path="/redirect" component={OauthRedirectPage} />
            <Route
              path="*/saml/onboarding/welcome/:email"
              component={SamlWelcomePage}></Route>
            <Route
              exact
              path="/saml/onboarding/profile-setup/:email"
              component={SamlProfileSetupPage}></Route>
            <Route
              exact
              path="/saml/onboarding/success"
              component={SamlSuccessPage}></Route>
            <Route exact path="/resend-invite" component={ResendInvitePage} />
            {/* please read the notice at the top of the route definition before adding new routes here */}
            <Route path="*" component={PageNotFound} />
          </Switch>
        </RoutesGuardWrapper>
      </Layout>
    </Router>
  );
};

export default AppRouter;
