import React from 'react';
import {FormattedMessage} from 'react-intl';
import htmlParse from 'html-react-parser';
import ErrorComponent from '../Helper/ErrorComponent';

const SamlLoginFailedError = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const eventId = searchParams.get('error');

  return (
    <ErrorComponent
      errorContent={
        <FormattedMessage id="GENERAL_SAML_ERROR_BODY">
          {(chunks) => htmlParse(chunks.join(''))}
        </FormattedMessage>
      }
      errorHeader={<FormattedMessage id="GENERAL_SAML_ERROR" />}
      eventId={eventId}
    />
  );
};

export default SamlLoginFailedError;
