import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Header} from 'semantic-ui-react';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import TogglerFormInput from '../../Team/UserEditPage/TogglerFormInput';
import InfoMessageBox from '../../commons/InfoMessageBox';

const SamlSignInMethods = ({credentialsAuthAllowed}) => {
  return (
    <>
      <Header>
        <FormattedMessage id="SIGN_IN_METHODS_TITLE"></FormattedMessage>
      </Header>
      <InfoMessageWrapper>
        <FormattedMessage id="SIGN_IN_METHODS_DESCRIPTION"></FormattedMessage>
      </InfoMessageWrapper>
      <TogglerFormInput
        name="credentialsAuthAllowed"
        valueLabel={
          credentialsAuthAllowed
            ? 'ALLOW_CREDENTIALS_SIGN_IN'
            : 'DONT_ALLOW_CREDENTIALS_SIGN_IN'
        }
        checked={credentialsAuthAllowed}></TogglerFormInput>
      <InfoMessageBox
        messageId={
          credentialsAuthAllowed
            ? 'ALLOW_CREDENTIALS_SIGN_IN_INFO'
            : 'DONT_ALLOW_CREDENTIALS_SIGN_IN_INFO'
        }></InfoMessageBox>
    </>
  );
};

SamlSignInMethods.propTypes = {
  credentialsAuthAllowed: PropTypes.bool.isRequired,
};

export default SamlSignInMethods;
