import React, {useState} from 'react';
import qs from 'qs';
import axios from 'axios';
import {intlShape, toast} from '@ecosio/components';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import {historyShape, locationShape} from '../../../shapes';
import {stringifyQuery} from '../../helpers/utils';
import {
  ContentBox,
  ContinueButton,
  Layout,
  Header,
  HeaderImage,
  TextContainer,
  Subtitle,
  InfoParagraph,
} from './InviteLayout';

export const maskToken = (query) => {
  try {
    if (!query || !query.token) {
      return query;
    }

    const token = query.token;
    const masked =
      typeof token === 'string'
        ? token.substring(0, 4) + token.substring(4).replaceAll(/[^-]/gi, '*')
        : '';

    return {
      ...query,
      token: masked,
    };
  } catch (error) {
    console.error(`Unable to mask token: ${error.message}`);
    return query;
  }
};

const WelcomePage = ({intl, location, history}) => {
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const {token, email, invitingUser, companyName} = query;

  const [loading, setLoading] = useState(false);

  const onContinue = async () => {
    try {
      setLoading(true);
      const response = await axios({
        method: 'POST',
        url: '/api/invite/login',
        data: {email, token},
      });

      setLoading(false);
      if (response.status === 200) {
        history.push({
          pathname: '/invite/profile-setup',
          search: stringifyQuery({
            email,
            token,
            type: response?.data?.companyType,
          }),
        });
      } else {
        // ?
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 401) {
        toast({
          title: intl.formatMessage({id: 'GENERAL_ERROR'}),
          description: intl.formatMessage({id: 'INVITE_TOKEN_EXPIRED'}),
          type: 'error',
        });
      } else {
        console.error(err);
        toast({
          title: intl.formatMessage({
            id: 'GENERAL_ERROR',
          }),
          description: err.message,
          type: 'error',
          time: 60000,
        });
      }
    }
  };

  return (
    <Layout>
      <ContentBox>
        <HeaderImage url="eco_welcome.svg" />

        <TextContainer>
          <Header>
            <FormattedMessage id="WELCOME_PAGE_HEADER" />
          </Header>
          <Subtitle>{email}</Subtitle>
          <InfoParagraph>
            <FormattedMessage
              id="WELCOME_PAGE_PARAGRAPH"
              values={{invitingUser, companyName}}
            />
          </InfoParagraph>
        </TextContainer>

        <ContinueButton
          onClick={onContinue}
          data-spec="start-button"
          disabled={loading}
          loading={loading}>
          <FormattedMessage id="WELCOME_PAGE_CONTINUE" />
          <Icon name="caret right" />
        </ContinueButton>
      </ContentBox>
    </Layout>
  );
};

WelcomePage.propTypes = {
  location: locationShape.isRequired,
  history: historyShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(WelcomePage);
