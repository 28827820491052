import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {stringifyQuery} from '../../../../helpers/utils';
import {historyShape, locationShape} from '../../../../../shapes';

const getOptionFromParams = (params, options) => {
  const optionFromQueryParam = options.find((opt) => {
    return params[opt.urlParam] === `${opt.urlParamValue}`;
  });
  return optionFromQueryParam || null;
};

const UserFilterDropdown = ({
  options,
  placeholder,
  dataSpec,
  location,
  history,
  onChange,
}) => {
  const params = qs.parse(location.search, {ignoreQueryPrefix: true});

  const selectionChanged = (_, {value}) => {
    const newParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    options.forEach((option) => delete newParams[option.urlParam]);
    delete newParams.page;
    const newSelection = options.find((opt) => opt.value === value);

    const params = {
      ...newParams,
      [newSelection.urlParam]: newSelection.urlParamValue,
    };
    history.push({
      pathname: location.pathname,
      search: stringifyQuery(params),
    });

    if (onChange) {
      onChange(value, newSelection.urlParam);
    }
  };

  const selectedOption = getOptionFromParams(params, options);

  return (
    <Dropdown
      data-spec={dataSpec}
      selectOnNavigation={false}
      selectOnBlur={false}
      options={options.map((opt) => ({
        text: opt.displayValue,
        value: opt.value,
      }))}
      search
      selection
      placeholder={placeholder}
      value={selectedOption?.value || ''}
      onChange={selectionChanged}
      style={{width: '100%'}}
    />
  );
};

UserFilterDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      displayValue: PropTypes.string,
      urlParam: PropTypes.string,
      urlParamValue: PropTypes.any,
    }),
  ),
  placeholder: PropTypes.string,
  urlParam: PropTypes.string,
  dataSpec: PropTypes.string,
  history: historyShape.isRequired,
  location: locationShape.isRequired,
  onChange: PropTypes.func,
};

export default UserFilterDropdown;
