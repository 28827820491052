import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import logger from '../../logger';

const debug = logger('subcompany-dropdown');

// maximum length of a name that doesn't explode the layout
const MAX_COMPANY_NAME_LEN = 45;

export function trimCompanyName(name) {
  if (!name) {
    return '';
  } else if (name.length > MAX_COMPANY_NAME_LEN) {
    return `${name.substring(0, MAX_COMPANY_NAME_LEN - 1)}…`;
  } else {
    return name;
  }
}

const SubCompanyDropdown = ({
  intl,
  style,
  onChange: onChangeProp,
  selection,
}) => {
  const history = useHistory();
  const userConfig = useSelector((state) => state.config?.userConfig);
  const configSubCompanies = userConfig.subCompanies || [];

  if (!Array.isArray(configSubCompanies)) {
    console.warn('SubCompanies is not an array: ', configSubCompanies);
    return null;
  }

  // hide the dropdown if there are no sub-companies
  if (configSubCompanies.length === 0) {
    debug('No subCompanies in config, not showing dropdown');
    return null;
  }

  debug(`Loaded ${configSubCompanies.length} subCompanies from redux store.`);

  const allCompanies = [
    {uuid: userConfig.company.uuid, name: userConfig.company.name},
    ...configSubCompanies,
  ];

  const subCompanies =
    allCompanies.map(({name, uuid}) => {
      return {
        text:
          uuid === userConfig?.company?.uuid
            ? `${trimCompanyName(name)} (${intl.formatMessage({
                id: 'PARENT_COMPANY',
              })}) `
            : trimCompanyName(name),
        value: uuid,
      };
    }) || [];

  const onChange = async (e, {value}) => {
    debug(`On change subCompany: new subCompany -> ${value}`);
    onChangeProp && onChangeProp(value);
    debug(`finished onChange, history: ${history}`);
  };

  return (
    <Dropdown
      data-spec="sub-comp-dd"
      selectOnNavigation={false}
      selectOnBlur={false}
      options={subCompanies}
      search
      selection
      value={selection || userConfig?.company?.uuid}
      onChange={onChange}
      style={style}
    />
  );
};

export default injectIntl(SubCompanyDropdown);
