import {intlShape, toast} from '@ecosio/components';
import {ecosioColors} from '@ecosio/customer-layout';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {Button, Icon, Label, Popup, Table} from 'semantic-ui-react';
import {styled} from 'styled-components';

import {Authority} from '@ecosio/auth';
import {selectUnit} from '@formatjs/intl-utils';
import {stringifyQuery} from '../../../../helpers/utils';
import ConfirmationModal from '../../../Modals/ConfirmationModal/ConfirmationModal';
import RequiresAuthority from '../../../RequiresAuthority';
import {InnerContainer} from '../styledComponents';
import {useUserConfig} from '../../../../hooks/useUserConfig';
import {MainCategory, trackEvent} from '../../../../analytics';
import {cellWidths, Permissions} from './dataGridConfig';

const UserInvitesBox = styled.div`
  background-color: ${ecosioColors.greyShades[5]};
  margin-top: 40px;
  margin-bottom: 20px;
`;

const Header = styled.h2`
  font-size: 15px;
  border: 1px solid ${ecosioColors.greyShades[5]};
  color: ${(props) => props.theme.secondaryColor};
`;

const Row = styled.div`
  padding: 20px 24px;
`;

const StyledTable = styled(Table)`
  margin: 0 !important;
  tr {
    background-color: ${ecosioColors.greyShades[5]};
  }
`;

// Override the pointer-events: none that the Icon inherits
// when the table row is disabled
const StyledIcon = styled(Icon)`
  pointer-events: auto;
`;

const TransparentLabel = styled(Label)`
  color: ${(props) =>
    props.disabled ? 'rgba(40,40,40,.3)' : 'black'} !important;
  background: transparent !important;
  border: 1px solid
    ${(props) =>
      props.disabled
        ? 'rgba(34, 36, 38, .15)'
        : 'rgba(34, 36, 38, 1)'} !important;
`;

export const INVITES_PER_PAGE = 10;

export const InviteValidity = ({expiresAt}) => {
  const intl = useIntl();
  const now = moment();
  const expires = moment(expiresAt);
  const {value, unit} = selectUnit(expires.toDate());
  const duration = intl.formatRelativeTime(value, unit);

  if (expires.isAfter(now)) {
    return (
      <div data-spec="active-invite">
        <Icon name="clock outline" data-spec="invite-clock" />
        <FormattedMessage
          id="USER_INVITE_VALID_TIMESTAMP"
          values={{duration}}
        />
      </div>
    );
  } else {
    return (
      <div data-spec="expired-invite">
        <FormattedMessage
          id="USER_INVITE_INVALID_TIMESTAMP"
          values={{duration}}
        />
      </div>
    );
  }
};

export const InviteRow = ({invite, onDelete}) => {
  let labelId = invite.expired ? 'USER_INVITE_EXPIRED' : 'USER_INVITE_VALID';
  let iconDescription = invite.expired
    ? 'USER_INVITE_EXPIRED_DESCRIPTION'
    : 'USER_INVITE_VALID_DESCRIPTION';
  let iconName = invite.expired ? 'history' : 'clock';

  if (invite.consumed) {
    labelId = 'USER_INVITE_CONSUMED';
    iconName = 'checkmark';
    iconDescription = 'USER_INVITE_CONSUMED_DESCRIPTION';
  }

  return (
    <Table.Row
      key={invite.uuid}
      disabled={invite.expired}
      data-spec={`invite-row-${invite.email}`}>
      <Table.Cell width={cellWidths[0]} textAlign="center">
        <Popup
          content={<FormattedMessage id={iconDescription} />}
          trigger={<Icon name={iconName} />}
        />
      </Table.Cell>
      <Table.Cell width={cellWidths[1]}>{invite.email}</Table.Cell>
      <Table.Cell width={cellWidths[2]}>
        <TransparentLabel basic disabled={invite.expired}>
          <FormattedMessage id={labelId} />
        </TransparentLabel>
      </Table.Cell>
      <Table.Cell width={cellWidths[3]}>{invite.companyName}</Table.Cell>
      <Table.Cell width={cellWidths[4]}>
        <Permissions permissions={invite.permissions} uuid={invite.uuid} />
      </Table.Cell>
      <Table.Cell width={cellWidths[5]}>
        <InviteValidity expiresAt={invite.expiresAt} />
      </Table.Cell>
      <Table.Cell width={cellWidths[6]} style={{textAlign: 'center'}}>
        <RequiresAuthority requires={Authority.COMPANY_USER_ADD}>
          <StyledIcon name="close" link onClick={() => onDelete(invite.uuid)} />
        </RequiresAuthority>
      </Table.Cell>
    </Table.Row>
  );
};

InviteRow.propTypes = {
  invite: PropTypes.object.isRequired,
  intl: intlShape,
  onDelete: PropTypes.func.isRequired,
};

const handleError = (error, intl) => {
  console.error(error);
  toast({
    title: intl.formatMessage({id: 'GENERAL_ERROR'}),
    description: intl.formatMessage({id: 'USER_INVITE_LIST_ERROR'}),
    type: 'error',
  });
};

const UserInviteList = ({intl, forceRefresh}) => {
  const [invites, setInvites] = useState(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState(null);
  const [loadMoreHidden, setLoadMoreHidden] = useState(true);
  const userConfig = useUserConfig();

  const params = qs.parse(location.search, {ignoreQueryPrefix: true});
  const query = stringifyQuery(params, {addQueryPrefix: true});

  const trackAnalytics = (event) => {
    const segmentation = {
      mainCategory: MainCategory.ButtonClick,
      companyUuid: userConfig?.company?.uuid,
      companyName: userConfig?.company?.name,
    };

    trackEvent(event, segmentation);
  };

  const fetchInvites = async (invitesCount = INVITES_PER_PAGE) => {
    try {
      const res = await axios.get(`/api/company/users/invite${query}`, {
        params: {
          sort: 'creationDate,desc',
          size: invitesCount,
        },
      });

      const invitesBody = res.data;
      setInvites(invitesBody);
      setLoadMoreHidden(
        !invitesBody ||
          invitesBody.numberOfElements === invitesBody.totalElements,
      );
    } catch (e) {
      return handleError(e, intl);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, [query, forceRefresh]);

  const showConfirmModal = (uuid) => {
    setInviteToDelete(uuid);
    setConfirmModalVisible(true);
  };

  const handleDelete = async () => {
    if (!inviteToDelete) {
      return;
    }
    try {
      await axios.delete(`/api/company/users/invite/${inviteToDelete}`);
      setConfirmModalVisible(false);
      toast({
        title: intl.formatMessage({id: 'GENERAL_SUCCESS'}),
        description: intl.formatMessage({id: 'USER_INVITE_DELETED'}),
        type: 'success',
        time: 3000,
      });
      await fetchInvites();
    } catch (e) {
      handleError(e, intl);
    } finally {
      setInviteToDelete(null);
    }
  };

  // Do not render anything if there are no pending invites
  if (invites?.content.length === 0) {
    return null;
  }

  return (
    <>
      <UserInvitesBox className="user-invites">
        <ConfirmationModal
          open={confirmModalVisible}
          headerMessageId="CONFIRM_MODAL_HEADER"
          onOpen={() => setConfirmModalVisible(true)}
          onClose={() => setConfirmModalVisible(false)}
          onConfirm={handleDelete}>
          <FormattedMessage id="INVITE_CONFIRM_MODAL_TEXT" />
        </ConfirmationModal>

        <Row>
          <Header>
            <FormattedMessage id="INVITED_USERS_HEADER" />
          </Header>
        </Row>
        <StyledTable data-spec="invite-table">
          <Table.Body>
            {invites?.content.map((invite) => (
              <InviteRow
                invite={invite}
                key={invite.uuid}
                intl={intl}
                onDelete={() => {
                  showConfirmModal(invite.uuid);
                  trackAnalytics('Cancel invite');
                }}
              />
            ))}
          </Table.Body>
        </StyledTable>
      </UserInvitesBox>

      {!loadMoreHidden && (
        <InnerContainer style={{textAlign: 'center'}}>
          <Button
            data-spec="load-more-invites-btn"
            style={{minWidth: '120px', margin: '15px 0 15px 0'}}
            onClick={() => {
              fetchInvites(invites.content.length + INVITES_PER_PAGE);
              trackAnalytics('Load more invites');
            }}>
            <FormattedMessage id="LOAD_MORE" />
          </Button>
        </InnerContainer>
      )}
    </>
  );
};

UserInviteList.propTypes = {
  intl: intlShape.isRequired,
  forceRefresh: PropTypes.any,
};

export default injectIntl(UserInviteList);
