import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Modal, Form, Icon} from 'semantic-ui-react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form as FinalForm, Field} from 'react-final-form';
import {InputField, toast} from '@ecosio/components';
import MonitorModalContent from '../../Modals/MonitorModalContent';
import MonitorModalActionButtonFooter from '../../Modals/MonitorModalActionButtonFooter';
import {requiredEmail} from '../../../helpers/validators';
import {processValidationResult} from '../../../helpers/utils';
import {
  getBackendPermissions,
  PermissionsCheckboxes,
} from '../../Helper/Permissions';
import {addPartnerToRelation} from './partnerRelationsApi';

const actionButtonStyle = {
  textAlign: 'left',
};

const buttonIconStyle = {
  float: 'right',
};

const AddPartnerRelationUserModal = ({open, onSave}) => {
  const intl = useIntl();
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async ({email, ...rest}) => {
    const newPermissions = getBackendPermissions(rest);
    setSubmitting(true);
    try {
      await addPartnerToRelation(email, newPermissions);
      onSave();
      toast({
        title: intl.formatMessage({id: 'PARTNER_ADDED_SUCCESS'}),
        type: 'success',
        time: 4000,
      });
    } catch (error) {
      if (error?.response?.status === 400) {
        return processValidationResult(error.response.data, intl);
      } else {
        toast({
          title: intl.formatMessage({
            id: 'GENERAL_ERROR',
          }),
          description: error.message,
          type: 'error',
          time: 100 * 1000,
        });
        console.error(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} size="small" data-spec="partner-relation-add-modal">
      <MonitorModalContent
        onClose={onSave}
        header={<FormattedMessage id="ADD_PARTNER_RELATION" />}
        subHeader={<FormattedMessage id="ADD_PARTNER_RELATION_DESC" />}>
        <FinalForm
          onSubmit={onSubmit}
          render={({handleSubmit}) => (
            <Form
              onSubmit={handleSubmit}
              style={{marginTop: 0}}
              data-spec="new-partner-form">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <div style={{marginBottom: '20px'}}>
                      <Field
                        name="email"
                        fluid
                        autoFocus
                        translateError
                        component={InputField}
                        validate={requiredEmail}
                        label={intl.formatMessage({id: 'GENERAL_EMAIL'})}
                        placeholder="john.doe@example.com"
                        required
                        data-spec="partner-email"
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <PermissionsCheckboxes />
                  </Grid.Column>
                </Grid.Row>
                <MonitorModalActionButtonFooter>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    data-spec="save"
                    type="submit"
                    style={Object.assign(
                      {float: 'right', margin: 0, width: 220},
                      actionButtonStyle,
                    )}>
                    <FormattedMessage id="ADD_PARTNER_USER" />
                    <Icon
                      name="chevron right"
                      style={Object.assign({}, buttonIconStyle)}
                    />
                  </Button>
                </MonitorModalActionButtonFooter>
              </Grid>
            </Form>
          )}
        />
      </MonitorModalContent>
    </Modal>
  );
};

AddPartnerRelationUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddPartnerRelationUserModal;
