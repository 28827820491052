import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {logout} from '@ecosio/auth';
import {Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';

class LogoutPage extends React.Component {
  componentDidMount() {
    if (!this.props.authServer) {
      return;
    }

    this.doLogout();
  }

  componentDidUpdate() {
    if (this.props.authServer) {
      this.doLogout();
    } else {
      console.error('Could not logout user', JSON.stringify(this.props));
    }
  }

  doLogout = () => {
    this.props.logout(axios, this.props.authServer);
  };

  render() {
    return <Loader active />;
  }
}

LogoutPage.propTypes = {
  logout: PropTypes.func,
  authServer: PropTypes.string,
};

const mapStateToProps = ({config}) => ({...config});
export default connect(mapStateToProps, {logout})(LogoutPage);
