import {CheckBoxField, dynID} from '@ecosio/components';
import PropTypes from 'prop-types';
import {default as React} from 'react';
import {Field} from 'react-final-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {FormField} from 'semantic-ui-react';
import {styled} from 'styled-components';

const TogglerFormInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0.66em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #5c5d5d;

  .inline.field {
    display: flex;
    align-items: center;
  }

  .checkbox {
    margin: 0 !important;
  }
`;

const TogglerInfo = styled.div`
  span + * {
    margin-left: 0.7rem;
  }
`;

const TogglerFormInput = ({
  name,
  label,
  valueLabel,
  icon,
  disabled,
  className,
}) => {
  const intl = useIntl();

  return (
    <FormField className={className}>
      {label && (
        <label style={{display: 'inline-block'}}>
          {intl.formatMessage({id: label})}
        </label>
      )}
      <TogglerFormInputWrapper>
        <TogglerInfo>
          <FormattedMessage
            id={dynID(valueLabel)}
            data-spec={`${name}-input`}></FormattedMessage>
          {icon}
        </TogglerInfo>
        <span>
          <Field
            name={name}
            component={CheckBoxField}
            toggle
            inline
            disabled={disabled}
            data-spec={`${name}-toggler`}
          />
        </span>
      </TogglerFormInputWrapper>
    </FormField>
  );
};

TogglerFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  valueLabel: PropTypes.string.isRequired,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default TogglerFormInput;
