import PropTypes from 'prop-types';

export const locationShape = PropTypes.shape({
  search: PropTypes.string.isRequired,
});

export const historyShape = PropTypes.shape({
  push: PropTypes.func.isRequired,
});

export const filterShape = PropTypes.shape({
  createdFrom: PropTypes.object,
  createdTo: PropTypes.object,
  senderUuid: PropTypes.string,
  receiverUuid: PropTypes.string,
});

export const downloadRequestShape = PropTypes.shape({
  id: PropTypes.number,
});
