import React from 'react';
import {Icon} from 'semantic-ui-react';
import {styled} from 'styled-components';
import {Pattern, PatternChild} from './FilePatternStyles';

const StyledIcon = styled(Icon)`
  color: ${(props) => {
    return props.theme.primaryColor;
  }};
  cursor: pointer;
`;

const getDataSpec = (keyword) =>
  typeof keyword?.toLowerCase === 'function'
    ? `pattern_${keyword.toLowerCase()}`
    : 'pattern_undefined';

const AddPattern = ({keywords = [], onHandleAddToken, isSeperator}) => {
  if (!Array.isArray(keywords)) {
    console.warn('No array passed as keyword, but ', typeof keywords);
    return null;
  }
  return (
    <div data-spec="add-pattern">
      {keywords.map((keyword) => {
        return (
          <Pattern
            onClick={() => onHandleAddToken(keyword, isSeperator)}
            key={keyword}
            data-spec={getDataSpec(keyword)}>
            <PatternChild>{keyword}</PatternChild>
            <PatternChild>
              <StyledIcon name="plus" />
            </PatternChild>
          </Pattern>
        );
      })}
    </div>
  );
};

export default AddPattern;
