import {CollapseBox, InputField, intlShape, toast} from '@ecosio/components';
import {selectUnit} from '@formatjs/intl-utils';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {Helmet} from 'react-helmet';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {
  Form,
  FormField,
  Header,
  Icon,
  Loader,
  Modal,
  Popup,
} from 'semantic-ui-react';
import {styled} from 'styled-components';

import {Authority} from '@ecosio/auth';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {historyShape} from '../../../../../shapes';
import {createPageTitle} from '../../../../helpers/helmetHelpers';
import {processValidationResult} from '../../../../helpers/utils';
import {required} from '../../../../helpers/validators';
import {Permissions, PermissionsCheckboxes} from '../../../Helper/Permissions';
import {useRequiresAuthority} from '../../../Helper/hooks';
import {ConfirmButton} from '../../../Login/LoginPageStyles';
import MonitorModalActionButtonFooter from '../../../Modals/MonitorModalActionButtonFooter';
import MonitorModalContent from '../../../Modals/MonitorModalContent';
import {SettingsPageContainer} from '../../FileNamePatternPage/FilePatternStyles';
import {FormFieldsWrapper} from '../../FormFieldsWrapper';
import SettingsHeader from '../../SettingsHeader';
import {SettingsFormWrapper} from '../../SettingsStyles';
import SubmitButton from '../../SubmitButton';
import InfoMessageBox from '../../commons/InfoMessageBox';
import SettingsPages from '../../settingsPages';
import TogglerFormInput from './TogglerFormInput';
import {fetchUserDetails, updateUserDetais} from './userEditClient';

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.width};
  margin-bottom: 0.7rem;

  .col--span-one {
    flex: 1;
  }

  .col--span-two {
    flex: 2;
  }

  .col--span-three {
    flex: 3;
  }

  div:not(:last-child) {
    margin-right: 1rem !important;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => {
    return props.theme.primaryColor;
  }};
  cursor: pointer;
`;

const OutputFieldWithRedirectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0.55rem 1rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #5c5d5d;
`;

const OutputFieldWithRedirect = ({
  label,
  value,
  redirectUrl,
  helperMsgId,
  className,
}) => {
  const history = useHistory();

  return (
    <FormField className={className}>
      <label style={{display: 'inline-block', marginRight: '0.5rem'}}>
        {label}
      </label>
      {helperMsgId && (
        <Popup
          trigger={<Icon name="info circle"></Icon>}
          content={
            <FormattedMessage id={helperMsgId}></FormattedMessage>
          }></Popup>
      )}
      <OutputFieldWithRedirectWrapper>
        {value}
        {redirectUrl && (
          <span>
            <StyledIcon
              name="external alternate icon"
              onClick={() => history.push(redirectUrl)}></StyledIcon>
          </span>
        )}
      </OutputFieldWithRedirectWrapper>
    </FormField>
  );
};

OutputFieldWithRedirect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  helperMsgId: PropTypes.string,
  className: PropTypes.string,
};

export const formatDate = (value, intl) => {
  if (!value) {
    return 'N/A';
  }
  const date = new Date(value);
  const fmt = selectUnit(date);
  return intl.formatRelativeTime(fmt.value, fmt.unit);
};

const prepareInitialValues = (user) => {
  // TODO: this should be an array field of permission strings mapped
  // to feature-flags on the backend
  if (user.partnerMatrixPermission) {
    user[Permissions.PARTNER_MATRIX] = true;
  }

  if (user.companyAdminPermission) {
    user[Permissions.COMPANY_ADMIN] = true;
  }

  if (user.messagingOperationsPermission) {
    user[Permissions.MONITOR_MESSAGING_OPERATIONS] = true;
  }

  if (user.elenaSupportTicketsPermission) {
    user[Permissions.ELENA_SUPPORT_TICKETS] = true;
  }
};

const prepareValuesToSend = (values) => {
  // TODO: proper generic mapping
  if (values['MONITOR_MESSAGING_OPERATIONS']) {
    values.messagingOperationsPermission = true;
  } else {
    values.messagingOperationsPermission = false;
  }

  if (values['PARTNER_MATRIX']) {
    values.partnerMatrixPermission = true;
  } else {
    values.partnerMatrixPermission = false;
  }

  if (values['COMPANY_ADMIN']) {
    values.companyAdminPermission = true;
  } else {
    values.companyAdminPermission = false;
  }

  if (values['ELENA_SUPPORT_TICKETS']) {
    values.elenaSupportTicketsPermission = true;
  } else {
    values.elenaSupportTicketsPermission = false;
  }
};

const UserEditPage = ({intl, match, history}) => {
  const {userId} = match.params;

  const {userConfig} = useSelector((state) => state.config);
  const loggedInUser = userConfig.email;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showingLogoutModal, setShowingLogoutModal] = useState(false);
  const [canDisableTotp, setCanDisableTotp] = useState(false);
  const isSubmittingExternally = useRef(false);

  let submit;

  useEffect(() => {
    fetchUserDetails(userId)
      .then((res) => {
        const user = res.data;
        prepareInitialValues(user);
        setUser(user);
        setLoading(false);
        setCanDisableTotp(res.data?.totpMfaEnabled);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast({
          type: 'error',
          title: intl.formatMessage({id: 'GENERAL_ERROR'}),
          description: error.message,
        });
      });
  }, [userId, intl]);

  useRequiresAuthority(Authority.COMPANY_USER_ADD);

  const onSubmit = (values) => {
    // TODO: create common handler for forms containing these permissions
    // using Permissions.jsx

    prepareValuesToSend(values);

    const isEditingOwnUser = userId === loggedInUser;

    // only show the modal when the user's permissions change, and
    // since a user cannot remove their own company admin permission,
    // that currently only means the messaging operations and partner matrix permissions.
    const permissionsChanged =
      values.messagingOperationsPermission !==
        user.messagingOperationsPermission ||
      values.elenaSupportTicketsPermission !==
        user.elenaSupportTicketsPermission ||
      values.partnerMatrixPermission !== user.partnerMatrixPermission;
    if (
      isEditingOwnUser &&
      permissionsChanged &&
      !isSubmittingExternally.current
    ) {
      setShowingLogoutModal(true);
      return;
    }

    delete values['MONITOR_MESSAGING_OPERATIONS'];
    delete values['PARTNER_MATRIX'];
    delete values['COMPANY_ADMIN'];
    delete values['ELENA_SUPPORT_TICKETS'];

    return new Promise((resolve, reject) => {
      updateUserDetais(userId, values)
        .then(() => {
          if (isEditingOwnUser && permissionsChanged) {
            history.push('/logout');
            resolve();
            return;
          }
          toast({
            type: 'success',
            time: 1.5 * 1000,
            title: intl.formatMessage({id: 'GENERAL_SUCCESS'}),
            description: intl.formatMessage({id: 'USER_EDIT_SAVE_SUCCESS'}),
          });
          history.push('/settings/team');
          resolve();
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            resolve(processValidationResult(error.response?.data, intl));
          } else {
            reject(error);
          }
        });
    });
  };

  // to be able to submit the form from the modal, which is outside the form
  const onSubmitExternally = (event) => {
    // we can't really pass arguments to the `onSubmit` function conventionally,
    // so use a ref to keep track of whether we want to submit the form
    isSubmittingExternally.current = true;
    submit(event);
  };

  return (
    <SettingsPageContainer>
      <SettingsHeader page={SettingsPages.TEAM} />
      <Helmet>
        <title>{createPageTitle(intl, 'TEAM_PAGE_TITLE')}</title>
      </Helmet>
      <Modal
        open={showingLogoutModal}
        onOpen={() => setShowingLogoutModal(true)}
        onClose={() => setShowingLogoutModal(false)}>
        <MonitorModalContent
          innerPadding
          onClose={() => setShowingLogoutModal(false)}
          header={<FormattedMessage id="USER_PAGE_LOGOUT_MODAL_HEADER" />}>
          <p
            style={{fontSize: '15px', lineHeight: '24px', padding: '1.5rem 0'}}>
            <FormattedMessage id="USER_PAGE_LOGOUT_MODAL_BODY" />
          </p>
          <MonitorModalActionButtonFooter>
            <ConfirmButton type="submit" onClick={onSubmitExternally}>
              <FormattedMessage id="GENERAL_OK" />
              <Icon name="chevron right" />
            </ConfirmButton>
          </MonitorModalActionButtonFooter>
        </MonitorModalContent>
      </Modal>

      <SettingsFormWrapper>
        <CollapseBox
          header={
            <FormattedMessage
              id="SETTINGS_USER_EDIT_HEADER"
              values={{email: userId}}
            />
          }>
          {loading && <Loader active />}
          {user && (
            <FinalForm
              onSubmit={onSubmit}
              initialValues={user}
              render={({handleSubmit, valid, values}) => {
                submit = handleSubmit;
                return (
                  <Form
                    onSubmit={handleSubmit}
                    id="user-edit-page-form"
                    data-spec="user-edit-form">
                    <FormFieldsWrapper>
                      <FormRow>
                        <div className="col--span-two">
                          <Field
                            name="userId"
                            type="text"
                            component={InputField}
                            disabled
                            label={intl.formatMessage({id: 'GENERAL_EMAIL'})}
                          />
                        </div>
                        {/* Outputs the origin IDP of the user: SAML IDP / ecosio */}
                        <OutputFieldWithRedirect
                          className="col--span-one"
                          label={intl.formatMessage({id: 'SOURCE'})}
                          value={values.userOrigin}
                          helperMsgId="USER_SOURCE_HELPER_MSG"
                          redirectUrl={values.userOriginUrl}
                        />
                      </FormRow>

                      <FormRow>
                        <div className="col--span-one">
                          <Field
                            name="firstName"
                            type="text"
                            component={InputField}
                            required
                            label={intl.formatMessage({
                              id: 'GENERAL_FIRST_NAME',
                            })}
                            validate={required}
                            translateError
                            data-spec="firstname-input"
                          />
                        </div>

                        <div className="col--span-one">
                          <Field
                            name="lastName"
                            type="text"
                            component={InputField}
                            required
                            label={intl.formatMessage({
                              id: 'GENERAL_LAST_NAME',
                            })}
                            validate={required}
                            translateError
                            data-spec="lastname-input"
                          />
                        </div>
                      </FormRow>

                      <FormRow>
                        <Field
                          name="creationDate"
                          type="text"
                          component={InputField}
                          disabled
                          label={intl.formatMessage({id: 'DATE_CREATED'})}
                          format={(value) => formatDate(value, intl)}
                          data-spec="creationdate-input"
                        />
                        <Field
                          name="lastChange"
                          type="text"
                          component={InputField}
                          disabled
                          label={intl.formatMessage({id: 'DATE_CHANGED'})}
                          format={(value) => formatDate(value, intl)}
                          data-spec="lastchange-input"
                        />
                        <Field
                          name="lastLogin"
                          type="text"
                          component={InputField}
                          disabled
                          label={intl.formatMessage({id: 'LAST_LOGIN_TIME'})}
                          format={(value) => formatDate(value, intl)}
                          data-spec="last-login-input"
                        />
                      </FormRow>

                      <Header as="h3">
                        <FormattedMessage id="ADMIN_ACTIONS" />
                      </Header>

                      <FormRow width="66%">
                        <TogglerFormInput
                          label="GENERAL_STATUS"
                          name="activated"
                          className="col--span-one"
                          disabled={user.samlEnabled}
                          valueLabel={
                            values?.activated
                              ? 'USER_STATUS_ACTIVE'
                              : 'USER_STATUS_INACTIVE'
                          }
                        />

                        <TogglerFormInput
                          label="MFA_SETTINGS_BOX_TITLE"
                          name="totpMfaEnabled"
                          className="col--span-one"
                          disabled={!canDisableTotp}
                          valueLabel={
                            values?.totpMfaEnabled
                              ? 'TOTP_ENABLED'
                              : 'TOTP_DISABLED'
                          }
                        />
                      </FormRow>

                      {user.samlEnabled && (
                        <InfoMessageBox messageId="SAML_USER_STATUS_INFO"></InfoMessageBox>
                      )}

                      <Header as="h3">
                        <FormattedMessage id="USER_PERMISSIONS" />
                      </Header>
                      <PermissionsCheckboxes
                        isAdminDisabled={user?.userId === loggedInUser}
                      />
                    </FormFieldsWrapper>
                    <SubmitButton
                      dataSpec="submitButton"
                      disabled={!valid}
                      textId="SAVE_CHANGES"
                    />
                  </Form>
                );
              }}
            />
          )}
        </CollapseBox>
      </SettingsFormWrapper>
    </SettingsPageContainer>
  );
};

UserEditPage.propTypes = {
  intl: intlShape.isRequired,
  history: historyShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
};

export default injectIntl(UserEditPage);
