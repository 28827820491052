import qs from 'qs';
import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Icon, Checkbox} from 'semantic-ui-react';
import {styled} from 'styled-components';
import htmlParse from 'html-react-parser';
import axios from 'axios';
import Label from '../Layout/Label';
import {
  ContentBox,
  ContinueButton,
  Header,
  HeaderImage,
  InfoParagraph,
  Layout,
  Subtitle,
  TextContainer,
} from './InviteLayout';

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const SuccessPage = ({location}) => {
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const name = query.name;

  const onSkip = () => {
    // Force a hard reload.
    window.location.href = '/account';
  };

  const onContinueToMfa = () => {
    // Force a hard reload.
    window.location.href = '/account/settings/account-security';
  };

  return (
    <Layout>
      <ContentBox>
        <HeaderImage url="ecosio_onboarding-success.svg" position="left" />
        <TextContainer>
          <Header>
            <FormattedMessage id="INVITE_SUCCESS_HEADER" />
          </Header>
          {name && <Subtitle data-spec="sub-name">{name}</Subtitle>}
          <InfoParagraph>
            <FormattedMessage id="INVITE_SUCCESS_PARAGRAPH" />
          </InfoParagraph>
          <TermsOfServiceInviteSuccessComponent location={location} />
        </TextContainer>

        <Footer>
          <Button
            style={{backgroundColor: 'white'}}
            type="button"
            onClick={onSkip}
            data-spec="skip-button">
            <Label messageId="GENERAL_SKIP" style={{margin: 0}}></Label>
          </Button>
          <ContinueButton
            size="180px"
            onClick={onContinueToMfa}
            data-spec="finishInviteButton">
            <FormattedMessage id="FINISH_WITH_MFA" />
            <Icon name="check" />
          </ContinueButton>
        </Footer>
      </ContentBox>
    </Layout>
  );
};

export default SuccessPage;

const TermsOfServiceInviteSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(421px - 4rem);
  margin-top: 1.5rem;
  line-height: 22px;
  overflow-wrap: anywhere;
`;

const TermsOfServiceInviteSuccessSubContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  ${(props) =>
    props?.disabled
      ? `
  pointer-events: none;
  opactiy: 0.4;
  color: grey;
  `
      : ``}
`;

const TermsOfServiceInfoBox = styled.div`
  display: flex;
  align-items: center;
  background: #e5edff;
  padding: 15px 27px;
`;

const TermsOfServiceInviteSuccessComponent = ({location}) => {
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    if (
      location?.state?.renderTermsOfServiceFlag &&
      !userProfile?.supplierTermOfServiceAccepted
    ) {
      axios
        .get('/api/user/profile')
        .then((res) => {
          setUserProfile(res?.data);
        })
        .catch((e) => console.error(e));
    }
  }, []);

  if (
    !location?.state?.renderTermsOfServiceFlag ||
    userProfile?.supplierTermOfServiceAccepted
  ) {
    return null;
  }

  return (
    <TermsOfServiceInviteSuccessContainer>
      <TermsOfServiceInviteSuccessSubContainer>
        <Checkbox
          data-spec="account-invite-page-terms-of-service-checkbox"
          onChange={async (e, data) =>
            await axios.post('/api/user/updateUserProperties', {
              supplierTermOfServiceAccepted: data?.checked,
              uuid: userProfile?.uuid,
            })
          }
        />
        <div
          data-spec="account-invite-page-terms-of-service-confirm-text"
          style={{
            marginLeft: '10px',
          }}>
          <FormattedMessage id="ACCOUNTS_INVITE_SUCCESS_TERMS_OF_SERVICE_CONFIRM_TEXT">
            {(chunks) => htmlParse(chunks.join(''))}
          </FormattedMessage>
        </div>
      </TermsOfServiceInviteSuccessSubContainer>
      <TermsOfServiceInfoBox>
        <Icon name="exclamation circle" size="big" style={{color: '#0054ff'}} />
        <div
          style={{marginLeft: '20px'}}
          data-spec="account-invite-page-terms-of-service-link-text">
          <FormattedMessage id="ACCOUNTS_INVITE_SUCCESS_TERMS_OF_SERVICE_LINK_TEXT">
            {(chunks) => htmlParse(chunks.join(''))}
          </FormattedMessage>
        </div>
      </TermsOfServiceInfoBox>
    </TermsOfServiceInviteSuccessContainer>
  );
};
