import {intlShape} from '@ecosio/components';
import axios from 'axios';
import {logout} from '@ecosio/auth';
import htmlParse from 'html-react-parser';
import PropTypes from 'prop-types';
import {parse} from 'qs';
import React from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {Button, Icon, Loader} from 'semantic-ui-react';
import {locationShape} from '../../../shapes';
import {CONTEXT_PATH, STATIC_ASSETS_BASE} from '../../constants';
import {isProd} from '../../helpers/utils';
import logger from '../../logger';
import {createPageTitle} from '../../helpers/helmetHelpers';
import InvalidConfigMessage from './InvalidConfigMessage';
import PasswordChangedMessage from './PasswordChangedMessage';
import {
  buttonStyle,
  ButtonWrapper,
  CenterContent,
  FormContainer,
  Header,
  HeaderWrapper,
  Illustration,
  LoginContainer,
  Logo,
  LogoLinkWrapper,
  samlButtonStyle,
  SecondaryLoginLink,
  SubHeader,
  WelcomeActionsWrapper,
  WelcomeWrapper,
} from './LoginPageStyles';
import SessionExpiredMessage from './SessionExpiredMessage';

const debug = logger('LoginPage');

const LOGIN_PATH = `${CONTEXT_PATH}/api/oauth2/authorization/cognito`;
const LOGIN_PREFIX = isProd() ? '' : 'http://localhost:8844';
export const LOGIN_URL = LOGIN_PREFIX + LOGIN_PATH;

export const INVALID_CONFIG = 'invalid-config';
export const PASSWORD_CHANGED = 'password-changed';
export const SESSION_EXPIRED = 'sessionExpired';

const SAML_LOGIN_URL = `${CONTEXT_PATH}/login/saml`;

export const isValidEcosioDomain = (url) => {
  return /^https:\/\/.*\.ecosio\.com|^http:\/\/127\.0\.0\.1|^http:\/\/localhost/.test(
    url,
  );
};

export const createRedirectUrl = (urlString) => {
  if (!isValidEcosioDomain(urlString)) {
    throw new Error('Invalid redirect domain');
  }

  if (urlString.indexOf('?') > -1) {
    return `${urlString}&fromLogin=true`;
  } else {
    return `${urlString}?fromLogin=true`;
  }
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    const sessionExpiredItem = window.sessionStorage.getItem(SESSION_EXPIRED);
    let isSessionExpired = false;

    if (sessionExpiredItem) {
      isSessionExpired = true;
      window.sessionStorage.removeItem(SESSION_EXPIRED);
    }

    const passwordChangedItem = window.sessionStorage.getItem(PASSWORD_CHANGED);
    let isPasswordChanged = false;

    if (passwordChangedItem) {
      isPasswordChanged = true;
      window.sessionStorage.removeItem(PASSWORD_CHANGED);
    }

    const searchParams = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const isInvalidConfig = searchParams?.reason === INVALID_CONFIG;

    this.state = {
      isSessionExpired,
      isInvalidConfig,
      isPasswordChanged,
      showInvalidConfig: window.sessionStorage.getItem(INVALID_CONFIG),
    };
  }

  backendLogout = () => {
    debug('Invalid config, calling oauth2 logout');
    window.sessionStorage.setItem(INVALID_CONFIG, 'true');
    this.props.logout(axios, this.props.authServer);
  };

  render() {
    if (this.state.isInvalidConfig) {
      this.backendLogout();
      return <Loader active />;
    }

    return (
      <LoginContainer>
        <Helmet>
          <title>{createPageTitle(this.props.intl, 'LOGIN_PAGE_TITLE')}</title>
          <description>
            {this.props.intl.formatMessage({id: 'LOGIN_PAGE_DESCRIPTION'})}
          </description>
        </Helmet>

        <CenterContent>
          <FormContainer className="login-form-container">
            <LogoLinkWrapper>
              <a
                href="https://www.ecosio.com"
                target="_blank"
                rel="noopener noreferrer">
                {/*TODO: upload to files.ecosio.com*/}
                <Logo
                  id="ecosio-logo"
                  src="https://assets.ecosio.com/98.0.0-beta.15/monitor/themes/monitor/assets/images/Logo.png"
                  alt="ecosio logo"
                />
              </a>
            </LogoLinkWrapper>
            <WelcomeWrapper>
              <HeaderWrapper>
                <Header>
                  <FormattedMessage id="LOGIN_WELCOME_MESSAGE_HEADER" />
                </Header>
                <SubHeader>
                  <FormattedMessage id="LOGIN_WELCOME_MESSAGE_SUBHEADER_1">
                    {(chunks) => htmlParse(chunks.join(''))}
                  </FormattedMessage>
                </SubHeader>
              </HeaderWrapper>
              {this.state.showInvalidConfig && <InvalidConfigMessage />}
              {this.state.isSessionExpired && <SessionExpiredMessage />}
              {this.state.isPasswordChanged && <PasswordChangedMessage />}
              <WelcomeActionsWrapper>
                <ButtonWrapper>
                  <Button
                    style={buttonStyle}
                    primary
                    fluid
                    as="a"
                    data-spec="login-link"
                    href={LOGIN_URL}>
                    <FormattedMessage id="GENERAL_GO_TO_LOGIN" />{' '}
                    <Icon name="chevron right" />
                  </Button>

                  <Button
                    style={samlButtonStyle}
                    className="saml-login-button"
                    basic
                    fluid
                    as="a"
                    data-spec="saml-login-link"
                    href={SAML_LOGIN_URL}>
                    <FormattedMessage id="GENERAL_GO_TO_SAML" />{' '}
                    <Icon name="chevron right" />
                  </Button>

                  <div>
                    <div style={{float: 'left'}}>
                      <SecondaryLoginLink
                        data-spec="password-reset-link"
                        to="/request-password-reset">
                        <FormattedMessage id="LOGIN_FORGOT_PASSWORD" />
                      </SecondaryLoginLink>
                    </div>
                    <div style={{float: 'right'}}>
                      <SecondaryLoginLink
                        data-spec="resend-invite-link"
                        to="/resend-invite">
                        <FormattedMessage id="LOGIN_RESEND_INVITE" />
                      </SecondaryLoginLink>
                    </div>
                  </div>
                </ButtonWrapper>
                <ButtonWrapper>
                  <HeaderWrapper>
                    <h3>
                      <FormattedMessage id="LOGIN_LEARN_MORE"></FormattedMessage>
                    </h3>
                  </HeaderWrapper>
                  <SubHeader>
                    <FormattedMessage id="LOGIN_LEARN_MORE_SUB" />
                  </SubHeader>
                  <Button
                    style={buttonStyle}
                    secondary
                    as="a"
                    data-spec="forgot-password-link"
                    target="_blank"
                    href="https://ecosio.com">
                    <FormattedMessage id="LOGIN_LEARN_MORE_ECOSIO" />{' '}
                    <Icon name="chevron right" />
                  </Button>
                </ButtonWrapper>
              </WelcomeActionsWrapper>
            </WelcomeWrapper>
          </FormContainer>
        </CenterContent>
        <Illustration
          className="login-illu"
          src={`${STATIC_ASSETS_BASE}/assets/illustration-login.svg`}
          alt="login illustration"
        />
      </LoginContainer>
    );
  }
}

LoginPage.propTypes = {
  location: locationShape.isRequired,
  intl: intlShape.isRequired,
  authServer: PropTypes.string.isRequired,
};

const mapStateToProps = ({config}) => ({...config});
export default injectIntl(connect(mapStateToProps, {logout})(LoginPage));
