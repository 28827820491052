import React from 'react';
import axios from 'axios';
import {useQueryClient} from '@tanstack/react-query';
import {useHistory} from 'react-router-dom';
import {processElenaValidationResult} from '../../../../helpers/validators';
import {AssigneeGroupAuthority, useRedirectIfNoPermission} from '../hooks';
import {useUserConfig} from '../../../../hooks/useUserConfig';
import {MainCategory, trackEvent} from '../../../../analytics';
import AssigneeGroupForm, {transformToSlug} from './AssigneeGroupForm';

const CreateAssigneeGroup = () => {
  useRedirectIfNoPermission(AssigneeGroupAuthority.EDIT);

  const queryClient = useQueryClient();
  const userConfig = useUserConfig();
  const companyUuid = userConfig?.company?.uuid;
  const companyName = userConfig?.company?.name;
  const history = useHistory();

  const onSubmit = async (values) => {
    try {
      delete values.members;
      values.name = transformToSlug(values.name);
      await axios.post(`/api/elena/external-assignee-groups`, values);
      await queryClient.invalidateQueries(['assignee-groups']);

      history.push('/settings/assignee-groups');
      trackEvent('Save new group', {
        mainCategory: MainCategory.ButtonClick,
        companyUuid,
        companyName,
      });
    } catch (e) {
      if (e.response?.status === 400) {
        return processElenaValidationResult(e.response.data);
      } else {
        console.error(e);
      }
    }
  };

  return (
    <AssigneeGroupForm
      header={{
        id: 'ASSIGNEE_GROUP_CREATE_HEADER',
      }}
      onSubmit={onSubmit}
      mode="create"
      initialValues={{members: [], externalCompanyUuid: companyUuid}}
    />
  );
};

export default CreateAssigneeGroup;
