import React from 'react';
import PropTypes from 'prop-types';
import DefaultTemplate from '../CustomLandingPage/Templates/Default';

const DynamicTemplate = ({componentName, options, ...rest}) => {
  const RenderError = () => {
    if (options && options.error) {
      const ErrorComponent = options.error;
      return <ErrorComponent />;
    } else {
      return <DefaultTemplate />;
    }
  };

  if (!componentName || typeof componentName !== 'string') {
    console.error(
      `[DynamicTemplate] please provide a componentName property (string) in options`,
    );
    return <RenderError />;
  }

  if (!options || !options.templates) {
    console.error(
      `[DynamicTemplate] please provide a template property in options`,
    );
    return <RenderError />;
  }

  if (!options.templates[componentName]) {
    console.error(
      `[DynamicTemplate] Dynamic Template named ${componentName} not found - rendering DEFAULT with Props: \n ${JSON.stringify(
        rest,
        null,
        2,
      )}`,
    );
    return <RenderError />;
  }

  if (options && options.debug) {
    console.info(rest);
  }

  const Template = options.templates[componentName];
  return <Template {...rest} />;
};

export default DynamicTemplate;

DynamicTemplate.propTypes = {
  componentName: PropTypes.string.isRequired,
  options: PropTypes.shape({
    templates: PropTypes.amy,
    debug: PropTypes.bool,
    error: PropTypes.any,
  }),
};
