import {styled} from 'styled-components';
import {Icon} from 'semantic-ui-react';

export const SettingsFormWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
`;

export const MessageInputWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

export const WarningIcon = styled(Icon)`
  color: ${(props) => props.theme.primaryColor};
  margin: 0 16px 0 0 !important;
`;
