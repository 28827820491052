import {intlShape, toast} from '@ecosio/components';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import {processValidationResult} from '../../../../../helpers/validators';
import MfaSetupModalPage from './MfaSetupModalPage';

/**
 * Modal page that checks whether the user has entered a correct totp.<br>
 * Its layout is based on the {@link MfaSetupModalPage} component
 * @param {*} props
 * - intl
 * - onSuccess: callback function called once the totp verification succeded
 * - onRedirect: callback function called once the totp verification succeded but the user is asked for TOTP check as well
 * @returns TOTPVerifier
 */
const TOTPVerifier = ({onSuccess, intl}) => {
  const verifyTotp = async ({totp}) => {
    try {
      const data = new FormData();
      data.append('userCode', totp);
      const response = await axios({
        method: 'POST',
        url: '/api/mfa/verify-totp',
        params: {userCode: totp},
      });
      if (response?.status < 300) {
        onSuccess();
      }
    } catch (err) {
      if (err.response.status === 400) {
        return processValidationResult({totp: 'ENTERED_CODE_INVALID'}, intl);
      }
      console.error(err);
      console.error(err.response);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: err.message,
        type: 'error',
      });
    }
  };

  return (
    <div>
      <MfaSetupModalPage
        intl={intl}
        title="VERIFY_WITH_CODE_MODAL_TITLE"
        description="VERIFY_WITH_CODE_MODAL_DESCRIPTION"
        formOptions={{
          input: {
            name: 'totp',
            type: 'number',
            label: 'ENTER_CODE',
          },
          onSubmit: verifyTotp,
          submitBtn: {
            id: 'DISABLE_MFA_BUTTON',
            isFinish: true,
          },
        }}></MfaSetupModalPage>
    </div>
  );
};

TOTPVerifier.propTypes = {
  onSuccess: PropTypes.func,
  intl: intlShape.isRequired,
};

export default TOTPVerifier;
