import createDecorator from 'final-form-calculate';
import {
  CUSTOM_ATTRIBUTE,
  DOMAIN_IDENTIFIER,
} from '../components/SamlCompanyMappingConfig';

/**
 * Decorator that sets the mapping attribute name accordingly depending on the mapping mode selected
 */
export const mappingAttributeDecorator = createDecorator({
  field: 'companyMappingConfig.mappingMode',
  updates: {
    'companyMappingConfig.mappingAttributeName': (
      mappingModeValue,
      allValues,
    ) => {
      if (mappingModeValue === DOMAIN_IDENTIFIER.value) {
        return 'domain_identifier';
      }
      if (mappingModeValue === CUSTOM_ATTRIBUTE.value) {
        return allValues.companyMappingConfig.mappingAttributeName;
      }
      return '';
    },
  },
});

/**
 * Calculates a SAML company mapping array based on the domain identifiers existing in the form
 * @param {*} formValues
 * @returns the SAML company mappings
 */
const companyMappingsFromDomainIdentifiers = (formValues) => {
  const rootCompany = formValues.rootCompanyUuid;
  const domainIdentifiers = formValues.domainIdentifiers;
  if (!domainIdentifiers) {
    return [{companyUuid: rootCompany, attributeValue: ''}];
  }
  const companyMappings =
    formValues.companyMappingConfig?.samlCompanyMappings || [];
  const companyMappingsMap = new Map(
    companyMappings.map((mapping) => [
      mapping.attributeValue,
      mapping.companyUuid,
    ]),
  );

  return domainIdentifiers.map((domainId) => {
    const mappedCompany = companyMappingsMap.get(domainId);
    return {
      companyUuid: mappedCompany || rootCompany,
      attributeValue: domainId,
    };
  });
};

/**
 * On usingCompanyMapping == true, initializes the company mapping config:
 * - in case of DOMAIN_IDENTIFIER mapping, adds rows for each domain iddentifier
 * - in case of CUSTOM_ATTRIBUTE mapping, leaves the mapping rows as they are
 */
export const companyMappingConfigDecorator = createDecorator({
  field: 'usingCompanyMapping',
  updates: {
    companyMappingConfig: (usingCompanyMappingValue, allValues) => {
      if (usingCompanyMappingValue) {
        if (!allValues.companyMappingConfig) {
          return {
            mappingMode: DOMAIN_IDENTIFIER.value,
            mappingAttributeName: 'domain_identifier',
            samlCompanyMappings:
              companyMappingsFromDomainIdentifiers(allValues),
          };
        }
        if (
          allValues.companyMappingConfig.mappingMode === DOMAIN_IDENTIFIER.value
        ) {
          return {
            ...allValues.companyMappingConfig,
            samlCompanyMappings:
              companyMappingsFromDomainIdentifiers(allValues),
          };
        }
      }
      return allValues.companyMappingConfig;
    },
  },
});

/**
 * Updated the company mapping rows on each change on domainIdentifiers field array
 */
export const companyMappingRowsDecorator = createDecorator({
  field: 'domainIdentifiers',
  updates: {
    ['companyMappingConfig.samlCompanyMappings']: (
      domainIdentifiers,
      allValues,
    ) => {
      if (!allValues.companyMappingConfig) {
        return;
      }
      if (
        allValues.companyMappingConfig.mappingMode === CUSTOM_ATTRIBUTE.value
      ) {
        return allValues.companyMappingConfig.samlCompanyMappings;
      }

      let companyMappingRows =
        allValues.companyMappingConfig.samlCompanyMappings || [];

      // The change was done on an existing domain identifier => update the corresponding row
      if (domainIdentifiers.length === companyMappingRows.length) {
        companyMappingRows = companyMappingRows.map((row, idx) => ({
          ...row,
          attributeValue: domainIdentifiers[idx],
        }));
      }
      // A domain identifier was added => adding a company mapping row for it
      else if (domainIdentifiers.length > companyMappingRows.length) {
        companyMappingRows.push({
          companyUuid: allValues.rootCompanyUuid,
          attributeValue: domainIdentifiers[domainIdentifiers.length - 1],
        });
      }
      // A domain identifier was deleted => removing the corresponding company mapping row
      else if (domainIdentifiers.length < companyMappingRows.length) {
        const rowIndexToDelete = companyMappingRows.findIndex(
          (row) => !domainIdentifiers.includes(row.attributeValue),
        );
        companyMappingRows.splice(rowIndexToDelete, 1);
      }
      return companyMappingRows;
    },
  },
});
