/**
 *
 * @param name {string}
 * @returns {string}
 */
const upperCaseFirstLetter = (name) => {
  return name.substring(0, 1).toUpperCase() + name.substring(1);
};

/**
 *
 * @param name {string}
 * @returns {string}
 */
const stripEmailSuffix = (name) => {
  const index = name.indexOf('+');
  if (index !== -1) {
    return name.substring(0, index);
  } else {
    return name;
  }
};

/**
 *
 * @param email {string}
 * @returns {string}
 */
export const fullNameFromEmail = (email) => {
  const [name] = email.split('@');
  const [first, last] = stripEmailSuffix(name).split('.');

  if (first && last) {
    return `${upperCaseFirstLetter(first)} ${upperCaseFirstLetter(last)}`;
  } else {
    return upperCaseFirstLetter(name);
  }
};
