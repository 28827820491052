import {createStore, applyMiddleware} from 'redux';
import {thunk} from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import rootReducer from './reducers';

const middleWares = [applyMiddleware(thunk)];

const initState = {};

const enhancer = composeWithDevTools(...middleWares);

export default createStore(rootReducer, initState, enhancer);
