import React, {useState} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {CollapseBox, toast, intlShape} from '@ecosio/components';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {styled} from 'styled-components';
import {Button, Icon, Popup} from 'semantic-ui-react';
import repeat from 'lodash/repeat';

import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import SettingsHeader from '../SettingsHeader';
import {SettingsFormWrapper} from '../SettingsStyles';
import SettingsPages from '../settingsPages';
import useCompany from '../../../hooks/useCompany';
import {createPageTitle} from '../../../helpers/helmetHelpers';

const Field = styled.div`
  width: 100%;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
`;

const Label = styled.span`
  color: #002268;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  width: 115px;
`;

const CopyToClipboardButton = ({value, intl, style}) => {
  const copyToClipboard = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(value);
      } catch (e) {
        console.warn('could not copy value to clipboard', e);
        return;
      }
    } else {
      window.clipboardData.setData('Text', value);
    }

    toast({
      type: 'success',
      time: 1.5 * 1000,
      title: intl.formatMessage({id: 'GENERAL_SUCCESS'}),
      description: intl.formatMessage({id: 'COPY_TO_CLIPBOARD_SUCCESSFUL'}),
    });
  };

  return (
    <Popup
      trigger={
        <Button
          secondary
          style={style}
          icon="copy"
          compact
          onClick={copyToClipboard}
        />
      }
      content={<FormattedMessage id="COPY_TO_CLIPBOARD" />}
    />
  );
};

CopyToClipboardButton.propTypes = {
  value: PropTypes.string,
  intl: intlShape,
  style: PropTypes.object,
};

const EInvoicePage = ({intl}) => {
  const {company} = useCompany();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const invoiceSettings = company?.invoiceSettings;
  const maskedPassword = repeat('*', invoiceSettings?.password?.length || 5);

  return (
    <SettingsPageContainer>
      <Helmet>
        <title>{createPageTitle(intl, 'EINVOICE_PAGE_TITLE')}</title>
      </Helmet>

      <SettingsHeader page={SettingsPages.E_INVOICING} />
      <SettingsFormWrapper>
        <CollapseBox
          header={<FormattedMessage id="TRUSTWEAVER_SETTINGS_HEADER" />}>
          <Field>
            <Label>
              <FormattedMessage id="GENERAL_USERNAME" />
            </Label>
            <CopyToClipboardButton
              style={{marginRight: '0.5rem'}}
              intl={intl}
              value={invoiceSettings?.username}
            />
            <span data-spec="einvoice-username">
              {invoiceSettings?.username}
            </span>
          </Field>

          <Field>
            <Label>
              <FormattedMessage id="GENERAL_PASSWORD" />
            </Label>
            <CopyToClipboardButton
              style={{marginRight: '0.5rem'}}
              intl={intl}
              value={invoiceSettings?.password}
            />
            <code data-spec="einvoice-password">
              {passwordVisible ? invoiceSettings?.password : maskedPassword}
            </code>
            <Button
              data-spec="toggle-password-button"
              style={{marginLeft: '1rem'}}
              compact
              onClick={() => setPasswordVisible((visible) => !visible)}>
              <Icon name={passwordVisible ? 'eye slash' : 'eye'} />
              <FormattedMessage
                id={passwordVisible ? 'HIDE_VALUE' : 'REVEAL_VALUE'}
              />
            </Button>
          </Field>

          <Field>
            <Label>
              <FormattedMessage id="TRUSTWEAVER_URL" />
            </Label>
            <span>
              <CopyToClipboardButton
                style={{marginRight: '0.5rem'}}
                intl={intl}
                value={invoiceSettings?.trustWeaverUrl}
              />
              <code data-spec="trustweaver-url">
                {invoiceSettings?.trustWeaverUrl}
              </code>
            </span>
          </Field>
        </CollapseBox>
      </SettingsFormWrapper>
    </SettingsPageContainer>
  );
};

EInvoicePage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(EInvoicePage);
