import PropTypes from 'prop-types';
import {intlShape, toast} from '@ecosio/components';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import QRCode from 'react-qr-code';
import {processValidationResult} from '../../../../../helpers/validators';
import MfaSetupModalPage from './MfaSetupModalPage';

/**
 * Modal page that shows the user a QR, and checks whether the software token was successfuly associated.<br>
 * Its layout is based on the {@link MfaSetupModalPage} component
 * @param {*} props
 * - intl
 * - onSuccess: callback function called once the token was successfuly verified
 * @returns MfaTokenVerifier
 */
const MfaTokenVerifier = ({intl, onSuccess}) => {
  const [totpUrl, setTotpUrl] = useState(null);

  useEffect(() => {
    const generateToken = async () => {
      try {
        const response = await axios.post('/api/mfa/token/generate');
        setTotpUrl(response.data);
      } catch (err) {
        console.error(err);
        toast({
          title: intl.formatMessage({
            id: 'GENERAL_ERROR',
          }),
          description: err.message,
          type: 'error',
        });
      }
    };

    generateToken();
  }, [intl]);

  const verifyToken = async ({verificationCode}) => {
    try {
      const response = await axios.post('/api/mfa/token/verify', null, {
        params: {verificationCode},
      });
      if (response?.status < 300) {
        onSuccess();
      }
    } catch (err) {
      if (err.response.status === 400) {
        return processValidationResult(
          {verificationCode: 'ENTERED_CODE_INVALID'},
          intl,
        );
      }
      console.error(err);
      toast({
        title: intl.formatMessage({
          id: 'GENERAL_ERROR',
        }),
        description: err.message,
        type: 'error',
      });
    }
  };

  return (
    <div>
      <MfaSetupModalPage
        intl={intl}
        title="SCAN_CODE_MODAL_TITLE"
        description="SCAN_CODE_MODAL_DESCRIPTION"
        output={
          totpUrl && (
            <QRCode
              size={256}
              style={{height: 'auto', maxWidth: '75%', width: '60%'}}
              value={totpUrl}
              viewBox="0 0 256 256"
              data-spec="qr-code"></QRCode>
          )
        }
        formOptions={{
          input: {
            name: 'verificationCode',
            type: 'number',
            label: 'ENTER_CODE',
          },
          onSubmit: verifyToken,
          submitBtn: {
            id: 'FINISH_MFA_SETUP_BUTTON',
            isFinish: true,
          },
        }}></MfaSetupModalPage>
    </div>
  );
};

MfaTokenVerifier.propTypes = {
  onSuccess: PropTypes.func,
  intl: intlShape.isRequired,
};

export default MfaTokenVerifier;
