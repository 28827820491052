import React, {useState} from 'react';
import {Input, InputArray, ErrorLabel, dynID} from '@ecosio/pathform';
import {Field} from 'react-final-form';
import {Button, Icon, Container, Input as SemInput} from 'semantic-ui-react';
import {FormattedMessage, useIntl} from 'react-intl';
import htmlParse from 'html-react-parser';
import {DropdownField} from '@ecosio/components';
import {required} from '../../../../helpers/validators';
import {CompanySettingsConstants} from '../../../../constants';
import {getComplexFieldConfig} from '../CompanyDynamicFields';

const ComplexCountryOfOrigin = ({formFieldMap = {}, formEnabled}) => {
  const intl = useIntl();

  const countryOfOriginFormFieldConfig = getComplexFieldConfig(
    formFieldMap,
    CompanySettingsConstants.SETTING_COMPLEX_COUNTRY_OF_ORIGIN,
  );

  if (!countryOfOriginFormFieldConfig) {
    return null;
  }
  //validation (arrayValidation): only one favourite checkbox.
  const defaultCountryFlagValidation = (values) => {
    if (!Array.isArray(values)) {
      return undefined;
    }

    let error = undefined;

    const favouriteCount = values.reduce((acc, curr) => {
      if (curr?.defaultCountry === true) {
        acc = acc + 1;
      }
      return acc;
    }, 0);

    if (values.length && favouriteCount !== 1) {
      error = 'COMPLEX_COUNTRY_OF_ORIGIN_ONE_DEFAULT_FLAG_ALLOWED';
    }

    return error;
  };

  //validation (arrayValidation): each countryOforigin selection should be different
  const uniqueCountryOfOriginsValidation = (values) => {
    if (!Array.isArray(values)) {
      return undefined;
    }

    let error = undefined;

    if (
      allCountriesExist(values) &&
      !isEverythingUnique(values, 'countryName')
    ) {
      error = 'DUPLICATE_COUNTRY_OF_ORIGINS';
    }

    return error;
  };

  const allCountriesExist = (arr) => {
    return arr.every((item) => item?.countryName);
  };

  const isEverythingUnique = (arr, key) => {
    const uniques = new Set(arr.map((item) => item[key]));
    return [...uniques].length === arr.length;
  };

  const countryOforiginLabel = countryOfOriginFormFieldConfig?.input?.label;

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined,
      );

  return (
    <InputArray
      name={`complexWebediProperties.${CompanySettingsConstants.SETTING_COMPLEX_COUNTRY_OF_ORIGIN}`}
      validate={composeValidators(
        defaultCountryFlagValidation,
        uniqueCountryOfOriginsValidation,
      )}>
      {({fields, meta}) => (
        <div style={{flex: '1', paddingTop: '14px'}}>
          <label className="themed">
            {' '}
            <FormattedMessage id={dynID(countryOforiginLabel)}>
              {(chunks) => {
                return htmlParse(chunks.join(''));
              }}
            </FormattedMessage>
          </label>
          <Container>
            <FormattedMessage id="COOP_COUNTRY_OF_ORIGIN_ENRICHMENT_DESCRIPTION" />
          </Container>
          {fields.map((name, index) => (
            <div
              style={{
                display: 'flex',
                paddingTop: '8px',
                gap: '15px',
              }}
              key={index}>
              <div style={{alignSelf: 'center'}}>
                <Field
                  disabled={!formEnabled}
                  name={`${name}.defaultCountry`}
                  component={CountryOfOriginCheckbox}
                />
              </div>
              <div style={{flexGrow: '1'}}>
                <Input
                  translateError
                  renderLabel={false}
                  data-spec={`company-page-${CompanySettingsConstants.SETTING_COMPLEX_COUNTRY_OF_ORIGIN}`}
                  path={
                    CompanySettingsConstants.SETTING_COMPLEX_COUNTRY_OF_ORIGIN
                  }
                  search
                  name={`${name}.countryName`}
                  validate={required}
                  placeholder={intl.formatMessage({
                    id: 'COMPLEX_COUNTRY_OF_ORIGIN_FIELD_PLACEHOLDER',
                  })}
                />
              </div>
              <div style={{flexGrow: '1'}}>
                <Field
                  disabled={!formEnabled}
                  style={{width: '100%'}}
                  name={`${name}.vatID`}
                  component={SemInput}
                  placeholder={intl.formatMessage({
                    id: 'COMPLEX_VAT_ID_FIELD_PLACEHOLDER',
                  })}
                />
              </div>
              <div style={{flexGrow: '1'}}>
                <Field
                  disabled={!formEnabled}
                  style={{width: '100%'}}
                  name={`${name}.internalVATVarianceCode`}
                  component={DropdownField}
                  options={[
                    {
                      text: intl.formatMessage({id: 'VAT_VARIANT_DEFAULT'}),
                      value: 'VAT_VARIANT_DEFAULT',
                    },
                    {
                      text: intl.formatMessage({id: 'COOP_VAT_VARIANT_0'}),
                      value: 'COOP_VAT_VARIANT_0',
                    },
                    {
                      text: intl.formatMessage({id: 'COOP_VAT_VARIANT_2_5'}),
                      value: 'COOP_VAT_VARIANT_2_5',
                    },
                    {
                      text: intl.formatMessage({id: 'COOP_VAT_VARIANT_2_6'}),
                      value: 'COOP_VAT_VARIANT_2_6',
                    },
                    {
                      text: intl.formatMessage({id: 'COOP_VAT_VARIANT_3_7'}),
                      value: 'COOP_VAT_VARIANT_3_7',
                    },
                    {
                      text: intl.formatMessage({id: 'COOP_VAT_VARIANT_3_8'}),
                      value: 'COOP_VAT_VARIANT_3_8',
                    },
                    {
                      text: intl.formatMessage({id: 'COOP_VAT_VARIANT_7_7'}),
                      value: 'COOP_VAT_VARIANT_7_7',
                    },
                    {
                      text: intl.formatMessage({id: 'COOP_VAT_VARIANT_8_1'}),
                      value: 'COOP_VAT_VARIANT_8_1',
                    },
                  ]}
                  placeholder={intl.formatMessage({
                    id: 'COMPLEX_VAT_RATE_FIELD_PLACEHOLDER',
                  })}
                />
              </div>
              <div>
                <Button
                  disabled={!formEnabled}
                  style={{
                    maxHeight: '38px',
                  }}
                  icon
                  negative
                  type="button"
                  onClick={() => fields.remove(index)}>
                  <Icon name="delete" />
                </Button>
              </div>
            </div>
          ))}

          {meta?.error && typeof meta?.error === 'string' && (
            <ErrorLabel error={meta.error} />
          )}
          <Button
            disabled={!formEnabled}
            onClick={(e) => {
              e.preventDefault();
              if (fields.length === 0) {
                fields.push({defaultCountry: true});
              } else {
                fields.push({defaultCountry: false});
              }
            }}
            style={{'margin-top': '10px'}}
            positive>
            <Icon name="add" />
            &nbsp;
            <FormattedMessage id="COOP_ADD_COUNTRY_OF_ORIGIN_BUTTON" />
          </Button>
          <br />
        </div>
      )}
    </InputArray>
  );
};

export default ComplexCountryOfOrigin;

const CountryOfOriginCheckbox = ({input, disabled}) => {
  const [state, setState] = useState(false);
  const onClick = (e) => {
    e.preventDefault();
    setState(!state);
    input.onChange(!state);
  };

  return (
    <Button
      {...input}
      disabled={disabled}
      onClick={onClick}
      icon="star"
      compact
      active={!input?.value}
    />
  );
};
