import axios from 'axios';
import PropTypes from 'prop-types';

export const fetchUserDetails = (userId) => {
  return axios.get(`/api/user/${userId}`);
};

export const updateUserDetais = (userId, updateBody) => {
  return axios.post(`/api/user/${userId}`, updateBody);
};

// we currently don't need this shape because this object only lives in the
// component state, but I'll leave it in here for reference/documentation
// purposes
export const userEditDtoShape = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  activated: PropTypes.bool,
  role: PropTypes.oneOf(['USER', 'COMPANY_ADMIN', 'OTHER']),
  userId: PropTypes.string.isRequired,
};
