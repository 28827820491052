export default class IdpSettings {
  constructor({
    providerName,
    domainIdentifiers,
    metadataUrl,
    email,
    firstName,
    lastName,
    companyUuid,
    credentialsAuthAllowed,
    companyMappingConfig,
    COMPANY_ADMIN,
    MONITOR_MESSAGING_OPERATIONS,
    PARTNER_MATRIX,
    ELENA_SUPPORT_TICKETS,
  }) {
    this.providerName = providerName;
    this.metadataUrl = metadataUrl;
    this.domainIdentifiers = domainIdentifiers;
    this.credentialsAuthAllowed = credentialsAuthAllowed;
    this.attributesMapping = {
      email,
      firstName,
      lastName,
    };
    this.companyUuid = companyUuid;
    this.companyAdminPermission = !!COMPANY_ADMIN;
    this.messagingOperationsPermission = !!MONITOR_MESSAGING_OPERATIONS;
    this.partnerMatrixPermission = !!PARTNER_MATRIX;
    this.supportTicketsPermission = !!ELENA_SUPPORT_TICKETS;
    this.companyMappingConfig = companyMappingConfig;
  }
}
