import {CheckBoxField, intlShape, toast} from '@ecosio/components';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Label from '../../../Layout/Label';
import {FormFieldsWrapper} from '../../FormFieldsWrapper';
import {InfoMessageWrapper} from '../../InfoMessageWrapper';
import {SettingsFormWrapper} from '../../SettingsStyles';
import StatusSummaryCollapseBox, {
  SettingContainer,
} from '../../commons/StatusSummaryCollapseBox';
import {StatusIcon} from '../commons/styledComponents';
import MfaSetupModal from './Modal/MfaSetupModal';
import {mfaSettingsActions, modalActions} from './mfaStore';

/**
 * Hook that returns an async function that can fetch the user's MFA settings and update the state accordingly
 * @returns fetchMfaSettings async function
 */
export const useFetchedMfaSettings = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  return useCallback(async () => {
    try {
      const response = await axios.get('/api/mfa/user/settings');
      dispatch(mfaSettingsActions.setTotpSettings(response.data));
    } catch (error) {
      console.error(error);
      toast({
        title: intl.formatMessage({id: 'GENERAL_ERROR'}),
        description: error.message,
        type: 'error',
      });
    }
  }, [dispatch, intl]);
};

/**
 * Content of the CollapseBox containing the MFA Settings
 * @param {*} intl
 * @returns MfaSettingsBox
 */
const MfaSettingsBox = ({intl}) => {
  const mfaSettings = useSelector((state) => state.mfaSettings);
  const dispatch = useDispatch();

  return (
    <>
      <InfoMessageWrapper>
        <FormattedMessage id="MFA_SETTINGS_BOX_DESCRIPTION"></FormattedMessage>
      </InfoMessageWrapper>
      <FormFieldsWrapper>
        <Label
          messageId={
            mfaSettings.totpEnabled ? 'ACTIVATED' : 'DISABLED'
          }></Label>
        <SettingContainer data-spec="totp-mfa-setting">
          <span>
            <span style={{margin: '1rem'}}>
              <StatusIcon
                enabled={mfaSettings.totpEnabled ? true : undefined}
                name="circle"
              />
            </span>
            <FormattedMessage id="TOTP_SETTING"></FormattedMessage>
          </span>
          <FinalForm
            onSubmit={() => dispatch(modalActions.open())}
            initialValues={mfaSettings.totpEnabled}
            render={() => {
              return (
                <Field
                  data-spec="totp-mfa-toggler"
                  name="totpMfaToggler"
                  component={CheckBoxField}
                  toggle
                  inverted={mfaSettings.totpEnabled}
                  style={{display: 'flex'}}
                  onChange={() => dispatch(modalActions.open())}
                  checked={mfaSettings.totpEnabled}
                />
              );
            }}></FinalForm>
        </SettingContainer>
      </FormFieldsWrapper>

      <MfaSetupModal intl={intl}></MfaSetupModal>
    </>
  );
};

MfaSettingsBox.propTypes = {
  intl: intlShape.isRequired,
};

/**
 * MFA Settings component, wrapped in a CollapseBox.
 * @returns MfaSettings
 */
const MfaSettings = ({mfaEnforcedOnCompany}) => {
  const intl = useIntl();
  const totpEnabled = useSelector((state) => state.mfaSettings.totpEnabled);
  const dispatch = useDispatch();
  const fetchMfaSettings = useFetchedMfaSettings();

  useEffect(() => {
    dispatch(mfaSettingsActions.setMfaEnforcedOnCompany(mfaEnforcedOnCompany));
  }, [dispatch, mfaEnforcedOnCompany]);

  useEffect(() => {
    fetchMfaSettings();
  }, [fetchMfaSettings]);

  return (
    <SettingsFormWrapper>
      <StatusSummaryCollapseBox
        headerId="MFA_SETTINGS_BOX_TITLE"
        dataSpec="mfa-col-box"
        checked={totpEnabled}
        summaryMsgId={totpEnabled ? 'ACTIVATED' : 'DISABLED_STATUS'}>
        <MfaSettingsBox intl={intl}></MfaSettingsBox>
      </StatusSummaryCollapseBox>
    </SettingsFormWrapper>
  );
};

MfaSettings.propTypes = {
  mfaEnforcedOnCompany: PropTypes.bool,
};

export default injectIntl(MfaSettings);
