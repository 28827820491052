import axios from 'axios';
import PropTypes from 'prop-types';

export const getPartnerRelations = () => {
  return axios.get('/api/partner-relations');
};

export const addPartnerToRelation = (userId, permissions) => {
  return axios.post('/api/partner-relations', {userId, permissions});
};

export const deleteUserFromRelation = (userId, partnerRelationUuid) => {
  return axios.delete(
    `/api/partner-relations/${partnerRelationUuid}/user/${userId}`,
  );
};

export const partnerRelationShape = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  consultants: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  partnerCompanyUuid: PropTypes.string.isRequired,
  partnerCompanyName: PropTypes.string.isRequired,
  standardCompanyUuid: PropTypes.string.isRequired,
  standardCompanyName: PropTypes.string.isRequired,
});
