import {ecosioColors} from '@ecosio/customer-layout';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Icon} from 'semantic-ui-react';
import {styled} from 'styled-components';
import Label from '../../../Layout/Label';

export const StatusIcon = styled(Icon)`
  color: ${(props) =>
    props.enabled ? props.theme.primaryColor : ecosioColors.greyShades[2]};
  margin: 1rem;
`;

export const ArrowButton = ({id, onClick, dataSpec}) => (
  <Button
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    onClick={onClick}
    data-spec={dataSpec}>
    <FormattedMessage id={id || 'Next'}></FormattedMessage>
    <Icon name="angle right" size="large"></Icon>
  </Button>
);
ArrowButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  dataSpec: PropTypes.string,
};

export const CheckmarkButton = ({id, onClick}) => (
  <Button
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    onClick={onClick}
    data-spec="checkmark-btn">
    <FormattedMessage id={id}></FormattedMessage>
    <Icon name="check" size="large"></Icon>
  </Button>
);
CheckmarkButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export const CancelButton = ({onClick}) => (
  <Button
    style={{backgroundColor: 'white'}}
    type="button"
    onClick={onClick}
    data-spec="cancel-mfa-setup-btn">
    <Label messageId="GENERAL_CANCEL"></Label>
  </Button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
};

export const CancelIcon = ({onClick}) => (
  <Icon
    onClick={onClick}
    name="close"
    style={{
      color: ecosioColors.greyShades[2],
      cursor: 'pointer',
      position: 'absolute',
      top: '5px',
      right: '5px',
    }}></Icon>
);

CancelIcon.propTypes = {
  onClick: PropTypes.func,
};
